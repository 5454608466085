import { useState, useEffect, createContext } from "react";

const initialState = {
    ibm: 0,
    dateRdt: '',
    nombreFinca:'',
    nombreDia:''
};


const RdtContext = createContext(initialState);

const RdtProvider = ({children}) =>{

    const [ rdtprov, setRdtprov ] = useState({})
    const [ reload, setReload ] = useState(false)
    const [ rdtEmploye, setRdtEmploye ] = useState({})
    const [ rdtEmployeDistribution, setRdtEmployeDistribution ] = useState({})

    useEffect(()=>{
        const autenticateUser = async() => {
           await setRdtprov(children)                  
        }        
        autenticateUser();
   }, [])
 
    return(
         <RdtContext.Provider
             value={{
                 rdtprov,
                 setRdtprov,
                 reload,
                 setReload,
                 rdtEmploye,
                 setRdtEmploye,
                 rdtEmployeDistribution,
                 setRdtEmployeDistribution
             }}
         >
             {children}
         </RdtContext.Provider>
    )
 }

export {
    RdtProvider
}

export default RdtContext;