import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from '../../../../hooks/useAuth';
import { useRdt } from '../../../../hooks/useRdt';
import { getToken, removeToken } from '../../../../utils/TokenLS';
import { showAlert, SW_ICON } from '../../../../utils/swAlert';
import { IoCloseCircleOutline, IoBookmark } from "react-icons/io5";
import { AnalysesPropertyTypesContainerColum, 
         AnalysesPropertyTypesContainerRow, 
         CerrarAnalysesRdt, 
         FormControlAnalysesRdt, 
         FormularioAnalysesRdt, 
         PropertyTable, 
         PropertyTableCell, 
         PropertyTableCellBH, 
         PropertyTableContainer, 
         PropertyTableHeader, 
         PropertyTableHeaderRow, 
         PropertyTableRow, 
         TittleAnalysesRdt } from './styles';
import { ButtonIcon } from '../../../../components/ButtonIcon';
import { HTTP_VERBS, requestHttp } from '../../../../utils/HttpRequest';
import { ButtonIconRibbon } from '../../../../components/ButtonIconRibbon';
import { Colors } from 'chart.js';
import { ButtonIconRibbonEmb } from '../../../../components/ButtonIconRibbonEmb';

const colors = [ 
    {id:1, cinta:'brown', name:'CAFE', nameCorto:'CA'},
    {id:2, cinta:'black', name:'NEGRA', nameCorto:'NE'}, 
    {id:3, cinta:'orange', name:'NARANJA', nameCorto:'NA'}, 
    {id:4, cinta:'green', name:'VERDE', nameCorto:'VE'}, 
    {id:5, cinta:'yellow', name:'AMARILLA', nameCorto:'AM'},   
    {id:6, cinta:'white', name:'BLANCA', nameCorto:'BL'},
    {id:7, cinta:'blue', name:'AZUL', nameCorto:'AZ'},
    {id:8, cinta:'gray', name:'GRIS', nameCorto:'GR'},   
    {id:9, cinta:'purple', name:'MORADA', nameCorto:'MO'},    
    {id:10, cinta:'red', name:'ROJA', nameCorto:'RO'},   
];

export const DetailAnalysesRdtsInv = ({ibm,fechafin,codLabor, global, sucursalName}) => {
    //console.log({global: global})
    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, reload, setReload, rdtEmploye  } = useRdt();

    //Buscar en calendario según fecha que se esta trabajando
    const [ calendar, setCalendar ] = useState([]);
    //Buscar analisis de rdts por cinta,lotes
    const [ rdtsudps, setRdtsudps ] = useState([]);

    const [ mostrarData, setMostrarData ] = useState({});

    const navigate = useNavigate();
        
    const { 
        register, 
        handleSubmit,
        formState:{
            errors,
            isValid
        }  
    } = useForm({ mode: 'onChange'});   

    //Token valido o no expirado
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token){
                    hendleCloseSeccion()
                } return                  
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[auth])
    //Close session if token expired
    const hendleCloseSeccion = async ()=>{
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Inicie Seccion Nuevamente', 
            "Su token puede estar vencido", 
            SW_ICON.INFO,
            () => { navigate('/Login')}    
        );
    }
    //Calendar       
    useEffect(() => {
        const getData = async ()=>{
            //console.log('obtener calendar');
            try {
                const token = getToken();
                if(!token) return                
                //await  cleanStates();                
                await getCalendar();                
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();         
              
    },[ibm,fechafin,codLabor])
    //rdtsUdps       
    useEffect(() => {
        const getData = async ()=>{
            //console.log('obtener calendar');
            try {
                const token = getToken();
                if(!token) return                
                //await  cleanStates();                               
                await getRdtsUdp();
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                     
    },[calendar])
    //getCalendars para prematuro y presente
    const getCalendar = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/calendars',                         
                    params: makePropertiesFiltersCalendar()                                     
                }
            );             
            const {data} = response;                
            setCalendar(data); 
            //console.log({calendar: data});       
            //console.log(calendar[0].cintaPm);    
            //console.log(calendar[0].cintaPs);                 
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersCalendar = ()=>{
        const filters = {};                     
            filters['Fecha_FIN_INV'] = fechafin; 
            filters['tipo'] = 'udps';                          
            //filters['employeesId'] = auth.data._id;              
            //console.log(fechaini);
        return filters;       
    }
    //getRdts x udp x cinta 
    const getRdtsUdp = async ()=>{      
        //console.log('haciendo la consulta de rdtsudps') 
        //console.log({filters: calendar[0].fini}); 
        if(!calendar[0]){
            setRdtsudps([])
            //console.log('Fecha Ini vacia')
            return
        }                            
        if(!fechafin){
            setRdtsudps([])
            //console.log('Fecha vacia')
            return                              
        }else{
            
          try {  
                              
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/Rdts/analysesudps',                                               
                    params: makePropertiesFilters()                                     
                }
            );             
            const {data} = response;                
            data?setRdtsudps(data):console.log('');
            //console.log({rdtsUdps: data})        
            } catch (error) {
                if(error.response.status === 401){                    
                    hendleCloseSeccion();
                }else{
                    //console.log('ErrorRdtsUdps')
                    console.log(error)
                }
            }
              //console.log({hacerConsulta: sucursalSeleted})        
        }                   
    }
    const makePropertiesFilters = ()=>{
        const filters = {}; 
            //console.log({filtersIni: 'Iniciando'});
            //filters['Fecha_INI'] = '2024-02-17'; 
            //Necesitamos organizar el calendario por la fini mas menor.
            const fechaMenor = calendar.reduce((min, actual) => {
                return new Date(actual.fini) < new Date(min.fini) ? actual : min;
            });
            //console.log({fechaMenor: fechaMenor.fini.split('T')[0]});              
            //filters['Fecha_INI'] = calendar[0].fini.split('T')[0];            
            filters['Fecha_INI'] = fechaMenor.fini.split('T')[0];            
            filters['Fecha_FIN'] = fechafin; 
            filters['IbmLaboro'] = ibm;   
            filters['IntCodigo_CONC'] = codLabor;
            filters['tipo'] = 'inv';            
        // filters['employeesId'] = auth.data._id;     
        //console.log({filters: calendar[0].fini.split('T')[0]});

        //console.log({filters: filters});
        
        return filters;       
    }
    //1. Eliminamos los objetos con totalCantidad igual a cero
    const rdtsudpsuno = rdtsudps.filter(objeto => objeto.totalCantidad !== 0 );    
    //console.log({rdtsudps: rdtsudps })
    //console.log({rdtsudpsuno: rdtsudpsuno })
    //2. Le hago un distinc a global y saco todos los lotes de la finca
    const Lotes = [...new Set(global.map((itemLote) => itemLote.Lote))];
    //console.log({Lotes: Lotes})
    //3. Le hago un distinc a calendar a las semanas
    const Semanas = [...new Set(calendar.map((itemSem) => itemSem.semana))];
    //console.log({Semanas: Semanas})
    //4. Le hago un distinc a calendar a las cintaPm
    const Cintas = [...new Set(calendar.map((itemSem) => itemSem.cintaPm))];
    //console.log({Cintas: Cintas})    
    //5. Agrego a las cintas el color de la cinta
    const cintasColors = [];
    //5. Iterar sobre Cintas y agregar el nombre de la cinta en el arreglo cintasColors
    Cintas.forEach(itemDos => {        
        //cintasColors = [{codCinta: itemDos, color:colors.id[itemDos].name}]    
        //console.log({itemDos:itemDos}) 
        //console.log({ColorsName: colors.name})
        const color = colors.find(color => color.id == itemDos)
        if(color){
          //cintaPs = color.cinta
          //nameCintaPs = color.name
          //console.log({ColorsName: color.name})
         const sem = calendar.find(cinta=>cinta.cintaPm === itemDos)
          //console.log({semCal: sem.semana})
          cintasColors.push({
            id: itemDos,
            cintaName: color.name,
            semana: sem.semana,
            orden: cintasColors.length
          })
        }
                
    });
    //console.log({Colors: colors})
    //console.log({cintasColors: cintasColors})    
    //Ordenar las cintas en forma descendente
    cintasColors.sort((a, b) => b.orden - a.orden);
    //6. Crear un nuevo arreglo para almacenar los resultados de rdtsudpsuno
    const nuevoArreglo = [];
    //6.1 Crear un conjunto para rastrear las combinaciones únicas de lote, cinta
    const combinacionesUnicas = new Set();
    //6.2 Iterar sobre los datos de rdtsudpsuno originales y guardar un nuevoArreglo 
    for (const cantidadLotes of rdtsudpsuno) {
        const { Lote, cinta, totalCantidad } = cantidadLotes; //Campos de rdtsudpsuno
        const combinacion = `${Lote}-${cinta}`;

        if (!combinacionesUnicas.has(combinacion)) {
            combinacionesUnicas.add(combinacion);//Guardo las combinaciones unicas de lote-cinta
        }
        
        //Guardo los datos de rdtsudpsuno solo de las combinaciones encontradas por lote-cinta en nuevoArreglo
        nuevoArreglo.push({                        
            Lote,
            cinta,            
            totalCantidad                     
        });
        
    }
    //console.log({combinacionesUnicas: combinacionesUnicas})
    //console.log({nuevoArreglo: nuevoArreglo})
    //7. Completar con los lotes faltantes por cinta para luego ser visualizados 
    //7.1 Recorro las cintas primero
    cintasColors.forEach(itemCinta=>{
        //console.log({itemCinta:itemCinta})
        //7.2 Luego los lotes 
        Lotes.forEach(itemLote=>{
            //console.log({itemLotes: itemLote, itemCinta:itemCinta})
            //7.3 por ultimo comparo con nuevoArreglo si tiene lotes y cintas iguales y alamaceno resultado en encontre y agrego a nuevo arreglo los que no estan 
            const encontre = nuevoArreglo.find(itemNA=>itemNA.Lote === parseInt(itemLote) && itemNA.cinta === itemCinta.cintaName)
            if(!encontre){
                nuevoArreglo.push({                                       
                    Lote: parseInt(itemLote),
                    cinta: itemCinta.cintaName,                    
                    totalCantidad: 0                    
                });
                //console.log({itemNA: encontre})
            }           
        })
        
    })   
    //console.log({nuevoArreglo: nuevoArreglo})
    //Mostrar
    //8. Función para organizar los elementos por cinta y lote
    const analisisOrganizado = {};
    const analisisColors = {};
    const analisisLotes = {};
    const analisisTotales = {};

    let totalNeta = 0;
    let totalBruta = 0;

    const organizarPorSemanaYLote =  () => {        

        nuevoArreglo.forEach(item => {                         
            // Si la semana no está en el objeto, inicializarla
            if (!analisisOrganizado[item.Lote]) {
                analisisOrganizado[item.Lote] = {};
            }
            // Si el lote no está en la semana, inicializarlo
            if (!analisisOrganizado[item.Lote][item.cinta]) {
                analisisOrganizado[item.Lote][item.cinta] = { totalCantidad: parseInt(item.totalCantidad) };
            }

            // Si la cinta no esta, inicializarlo
            if (!analisisTotales[item.cinta]) {
                analisisTotales[item.cinta] = {cantCinta:0};
            }
            // Agregar la suma de totalCantidad a la nueva columna cantCinta
            analisisTotales[item.cinta].cantCinta += item.totalCantidad;
                       
        });            
        //console.log({analisisOrganizado:analisisOrganizado})
        //console.log({analisisTotales:analisisTotales})
        
        //Para el color de las cintas en los encabezados
        colors.forEach(item=>{
            analisisColors[item.name] = {cinta: item.cinta, nameCorto: item.nameCorto}
        })
        //console.log({analisisColors:analisisColors}) 
        //Para la suma de areas netas y brutas + totales        
        global.forEach(item=>{
            analisisLotes[item.Lote] = {aneta: item.A_Neta, abruta: item.A_Bruta}
            //console.log({item: item})
            totalNeta += item.A_Neta
            totalBruta += item.A_Bruta
        }) 
        //console.log({totalNeta: totalNeta})
        //console.log({totalBruta: totalBruta})
        //console.log({analisisLotes:analisisLotes})      

    };    
    
    // Función para renderizar las tablas por semana
    const renderTablasPorSemana = () => {
        organizarPorSemanaYLote();        
    };
    
    //<ButtonIconRibbonEmb icon={IoBookmark} cinta={analisisColors[cinta.cintaName].cinta} />    

  return (
        <div>
        <h4>{sucursalName} &nbsp;&nbsp; Embolses digitados en los RDTs en las Ult 10 Semanas</h4>
        <PropertyTable>
            {renderTablasPorSemana()}
            <thead>
                <PropertyTableRow>
                    <PropertyTableHeader>LOTE</PropertyTableHeader>
                    <PropertyTableHeader>A_BRUTA</PropertyTableHeader>
                    <PropertyTableHeader>A_NETA</PropertyTableHeader>                    
                    {cintasColors.map(cinta=>(                         
                        <>
                        <PropertyTableHeader>
                            <PropertyTableHeader>SM {cinta.semana}</PropertyTableHeader>
                            {analisisColors[cinta.cintaName].nameCorto}                                                       
                            <ButtonIconRibbon icon={IoBookmark} cinta={analisisColors[cinta.cintaName].cinta} />                            
                        </PropertyTableHeader>
                        <PropertyTableHeader>BH</PropertyTableHeader>                        
                        </>
                    ))}
                </PropertyTableRow>
            </thead>
            <tbody>
                {Lotes.map(lote=>(
                    <PropertyTableRow key={lote}>
                        <PropertyTableHeader>{lote}</PropertyTableHeader>
                        <PropertyTableCell>{analisisLotes[lote].abruta}</PropertyTableCell>
                        <PropertyTableCell>{analisisLotes[lote].aneta}</PropertyTableCell>                        
                        {cintasColors.map(cinta=>(                           
                           <>
                           <PropertyTableCell key={`${lote}_${cinta.cintaName}`}>
                                {analisisOrganizado[lote][cinta.cintaName].totalCantidad}
                                
                            </PropertyTableCell>                            
                           <PropertyTableCellBH key={`${lote}_${cinta.cintaName}`}>{(analisisOrganizado[lote][cinta.cintaName].totalCantidad/analisisLotes[lote].aneta).toFixed(0)}</PropertyTableCellBH>                                                      
                           </>
                        ))}                        
                    </PropertyTableRow>
                ))}
            </tbody>
            <tfoot>
                <PropertyTableRow>
                    <PropertyTableHeader>TOTAL</PropertyTableHeader>
                    <PropertyTableHeader>{(totalBruta).toFixed(2)}</PropertyTableHeader>                    
                    <PropertyTableHeader>{(totalNeta).toFixed(2)}</PropertyTableHeader>                    
                    {cintasColors.map(cinta=>(
                        <>
                        <PropertyTableHeader>                            
                            {analisisTotales[cinta.cintaName].cantCinta }                                                       
                        </PropertyTableHeader>                        
                        <PropertyTableHeader>                            
                        {(analisisTotales[cinta.cintaName].cantCinta / totalNeta).toFixed(0) }                                                                              
                    </PropertyTableHeader>                    
                    </>
                    ))} 

                </PropertyTableRow>
            </tfoot>
        </PropertyTable>
        </div>
  )
}


