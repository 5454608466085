import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { useRdt } from "../../hooks/useRdt";
import Alert from "../../components/Alert/Alert";
import { Page } from "../../components/Page"
import { PageTitle } from "../../globalStyles";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { Button } from "../../components/Button"
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";
import { getToken, removeToken } from "../../utils/TokenLS";
import { AddPrecipitationWrapper_Global, 
        AddPrecipitationWrapper_Global_DOS, 
        PropertyTypesContainerColum, 
        PropertyTypesContainerRow,
        FormControl,           
        FormControlInput,
        FormControlInputSearch,          
        FormControlTitle,
        FormControlInputOther,
        FormControlInputudp,
        FormControlAction,
        FormControl_Global} from "./styles";
import { PropertyPrecipitations } from "./components/PropertyPrecipitations";
import { PropertyPrecipitationsSem } from "./components/PropertyPrecipitationsSem";
import { PropertyGrafLinealChart } from "./components/PropertyGrafLinealChart";
import { PropertyGrafBarChart } from "./components/PropertyGrafBarChart";
import { PropertyGrafBarChartmes } from "./components/PropertyGrafBarChartmes";
import { PropertyGrafDonaChartanio } from "./components/PropertyGrafDonaChartanio";

const ALL_SUCURSALES = 0;

export const AddPrecipitation = () => {

    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, setRdtprov, reload, setReload } = useRdt();
    const [ date, setDate ] = useState('');
    const [ sucursal, setSucursal ] = useState([]);
    const [ sucursalSeleted, setSucursalSelected ] = useState(ALL_SUCURSALES);  
    const [ sucursalname, setSucursalname ] = useState('');
    const [ cantidad, setCantidad ] = useState();    
    const [ precipitation, setPrecipitation ] = useState([]); 
    const [ precipitationUltsem, setPrecipitationUltsem ] = useState([]);
    const [ calendar, setCalendar ] = useState([]);  
    const [ sem, setSem ] = useState(0);     
    const [ anio, setAnio ] = useState(0); 
    
    const [alert, setAlert] = useState({})    

    const navigate = useNavigate();

    const { 
        register, 
        handleSubmit,
        formState:{
            errors,
            isValid
        }  
    } = useForm({ 
        mode: 'onChange',
        defaultValues: {
            checkbox: []
        }
   }); 

   //Close session if token expired
   const hendleCloseSeccion = async ()=>{
    closeSeccionAuth()
    removeToken()
    showAlert(
        'Inicie Seccion Nuevamente', 
        "Su token puede estar vencido", 
        SW_ICON.INFO,
        () => { navigate('/Login')}    
    );
   }
    //Token valido o no expirado
    useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return
            await getSucursalesAll();                      
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();               
    },[auth])
    //Recargar RDTs
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                await reload ? getPrecipitation():console.log('')                                              
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[reload])
    //Si estoy editando
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                await rdtprov.ibm?setSucursalSelected(rdtprov.ibm):console.log(''); 
                await rdtprov.dateRdt?setDate((rdtprov.dateRdt).split('T')[0]):console.log('');                                
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[rdtprov])
    //Sucursals       
    useEffect(() => {
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return
                await  cleanStates();      
                sucursalSeleted && date ? getCalendar():cleanStates();                                        
                sucursalSeleted && date ? getPrecipitation():cleanStates();                                                     
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();         
            
    },[sucursalSeleted])
    //date       
    useEffect(() => {
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return 
                await  cleanStates();                                              
                sucursalSeleted && date ? getCalendar():cleanStates();
                sucursalSeleted && date ? getPrecipitation():cleanStates();
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();         
            
    },[date])
    //calendar: para consultar precipitaciones entre las ultimas semanas       
    useEffect(() => {
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return 
                await  cleanStates();                                                              
                sucursalSeleted && date ? getPrecipitationUltSemanas():cleanStates();
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();         
            
    },[calendar])
    //Sucursales
    const handleChange = event => {
        setRdtprov({});
        setSucursalSelected(event.target.value)                 
    }
    //Date
    const handleChangedate = event => {
        setDate(event.target.value)                       
    }
    //Sucursals
    const getSucursalesAll = async ()=>{
    try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/sucursal',                         
                    params: makeSucursalesFilters()                                     
                }
            );             
            const {data} = response;                
            setSucursal(data);                                     
        } catch (error) {
            console.log(error)
        }
    }
    const makeSucursalesFilters = ()=>{
        const filters = {};           
        return filters;       
    }
    //Clean states
    const cleanStates = ()=>{
            
        if(sucursalSeleted === '20128'){
            setSucursalname('EDEN')
        }else if(sucursalSeleted === '90032'){
            setSucursalname('TULE')
        }else if(sucursalSeleted === '20065'){
            setSucursalname('JUANCA')
        }else if(sucursalSeleted === '80108'){
            setSucursalname('LA MONICA')
        }else if(sucursalSeleted === '23270'){
            setSucursalname('TIKAL')
        }else if(sucursalSeleted === '20947'){
            setSucursalname('BUENOS AIRES')
        }else if(sucursalSeleted === '80132'){
            setSucursalname('AGUA VERDE')
        }else if(sucursalSeleted === '20180'){
            setSucursalname('TOSCANA')
        }else if(sucursalSeleted === '20175'){
            setSucursalname('ASTURIAS')
        }else if(sucursalSeleted === '20185'){
            setSucursalname('LA CEJA')
        }else if(sucursalSeleted === '20186'){
            setSucursalname('ARIZONA')
        }else if(sucursalSeleted === '20188'){
            setSucursalname('ALCATRAZ')
        }else if(sucursalSeleted === '20194'){
            setSucursalname('MANAURE')
        }else if(sucursalSeleted === '90008'){
            setSucursalname('LA AGUADA')
        }else{
            setSucursalname('')
        }
    }
    //getCalendars
    const getCalendar = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/calendars',                         
                    params: makePropertiesFiltersCalendar()                                     
                }
            );             
            const {data} = response;                
            setCalendar(data);             
            setReload(false);
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersCalendar = ()=>{
        const filters = {};                
            filters['Fecha'] = date;             
            
            //filters['employeesId'] = auth.data._id;              
            //console.log(fechaini);
        return filters;       
    }
    //getPrecipitation
    const getPrecipitation = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/precipitations',                         
                    params: makePropertiesFilters()                                     
                }
            );             
            const {data} = response;                
            setPrecipitation(data);             
            setReload(false);
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFilters = ()=>{
        const filters = {};                
            filters['Fecha'] = date;             
            filters['Ibm'] = sucursalSeleted; 
            //Se envia el Group 2 para que el orden sea por fecha  
            filters['Group'] = 2;  
            //filters['employeesId'] = auth.data._id;              
            //console.log(fechaini);
        return filters;       
    }
    //getPrecipitationUltSemanas
    const getPrecipitationUltSemanas = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/precipitations',                         
                    params: makePropertiesFiltersUltSemanas()                                     
                }
            );             
            const {data} = response;                
            setPrecipitationUltsem(data);             
            setReload(false);
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersUltSemanas = ()=>{
        const filters = {};                                    
            filters['Ibm'] = sucursalSeleted;
            filters['anio'] = calendar?calendar[0].anio:console.log('');  
            filters['semana'] = calendar?calendar[0].semana:console.log('');   
            //Se envia el Group 2 para que el orden sea por fecha   
            filters['Group'] = 2;  
            setSem(calendar?calendar[0].semana:0)
            setAnio(calendar?calendar[0].anio:0)
            //filters['employeesId'] = auth.data._id;              
            //console.log(fechaini);
        return filters;       
    }
    //Grabar
    const onSubmitsave = async (data) =>{
          //Validaciones
          //Vallido que si este dentro de un rango permitido para actualizar
          //valido si dia es viernes o sabado                          
          if(numeroDia >= 5){
            //rango para el viernes de 4 dias.
            if(numeroDia == 5){                
                if(rango > 363599999){
                    setAlert({
                        msg: 'Usted ya no puede realizar registros de precipitación para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(()=>{
                        setAlert({})
                    }, 7000)            
                    return
                }
            }else{
                //rango para el sabado de 3 dias.                     
                    if(rango > 276999999){
                        setAlert({
                            msg: 'Usted ya no puede realizar registros de precipitación para este dia',
                            error: true
                        })
                        //console.log({mensaje: alert.msg}); 
                        setTimeout(()=>{
                            setAlert({})
                        }, 7000)            
                        return
                    }
            }
        }else{
            //Rango de 2 dias para registrar
            /*
            if(rango > 189999999){
                setAlert({
                    msg: 'Usted ya no puede realizar registros de precipitación para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(()=>{
                    setAlert({})
                }, 7000)            
                return
            }*/
        }    
        //Validaciones
        //Valido Fecha
        if(fecha > hoy){
            setAlert({
                msg: 'Fecha no puede ser "mayor" a la fecha actual',
                error: true
            })
            //console.log({mensaje: alert.msg}); 
            setTimeout(()=>{
                setAlert({})
            }, 7000)            
            return     
        }
        //Cantidad de precipitación para grabar.
        if(!data.cantidad){
            setAlert({
                msg: 'Se requiere precipitación del dia',
                error: true
            }) 
            //console.log({mensajeincludes: alert.msg}); 
            setTimeout(()=>{
                setAlert({})
            }, 7000)            
            return    
        }    
        setAlert({});        

        try {  
        
            const dataPrecipitation = {};
            dataPrecipitation['Ibm'] = sucursalSeleted;
            //Finca Reporta
            let nombreFincareporta = '';
            let orden = '';
            for (let j = 0; j < sucursal.length ; j++){            
                if(sucursalSeleted === sucursal[j].strCodigo){
                    nombreFincareporta = sucursal[j].strNombre
                    orden = sucursal[j].intOrden
                }                             
            }
            dataPrecipitation['strFinca'] = nombreFincareporta;
            dataPrecipitation['intPrecipitation'] = data.cantidad;
            dataPrecipitation['dtmFecha'] = data.fechardt;
            dataPrecipitation['anio'] = calendar[0].anio;
            dataPrecipitation['mes'] = calendar[0].mes;
            dataPrecipitation['semana'] = calendar[0].semana;
            dataPrecipitation['diaAnio'] = calendar[0].diaAnio;
            dataPrecipitation['diaMes'] = calendar[0].diaMes;
            dataPrecipitation['diaSemana'] = calendar[0].diaSemana;
            dataPrecipitation['nombreMes'] = calendar[0].nombreMes;
            dataPrecipitation['nombreDia'] = calendar[0].nombreDia;
            dataPrecipitation['nombrediaCorto'] = calendar[0].nombrediaCorto;                 
            dataPrecipitation['email_USUA'] = auth.data.email;
            dataPrecipitation['intOrden'] = orden;

            //console.log(dataPrecipitation['Ibm'])
            //console.log(dataPrecipitation['strFinca'])
            //console.log(dataPrecipitation['intPrecipitation'])
            //console.log(dataPrecipitation['dtmFecha'])
            //console.log(dataPrecipitation['email_USUA'])

            const response = await requestHttp(
                {
                    endpoint: '/precipitations',
                    body: dataPrecipitation
                }
            );            
            await getPrecipitation();
            await getPrecipitationUltSemanas();
            setCantidad('');                
            showAlert(
                'Grabado', 
                "Correctamente", 
                SW_ICON.SUCCESS,
                () => { FocusOnInput()}                       
            );
        } catch (error) {
            //console.log(error)
            setAlert({
                msg: error.response.data,
                error: true
            })
            setTimeout(()=>{
                setAlert({})                
            }, 10000) 
        }   

    }

    function FocusOnInput(){
        document.getElementById("input1").focus();
    }

    //Validar dia de la semana
    const fechaComoCadena = date +  " 00:01:59"; // día semana
    const dias = [
        'DOMINGO',
        'LUNES',
        'MARTES',
        'MIERCOLES',
        'JUEVES',
        'VIERNES',
        'SABADO',
    ];
    const numeroDia = new Date(fechaComoCadena).getDay();
    const nombreDia = dias[numeroDia];

    const fecha = new Date(fechaComoCadena);
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    
    const rango = new Date(hoy - fecha);

    const { msg } = alert;  


  return (
    <Page>
        <AddPrecipitationWrapper_Global>
            <AddPrecipitationWrapper_Global_DOS>
                <LogoImageWrapper>
                    <PageTitle>
                        REGISTRAR PRECIPITACIONES
                    </PageTitle>
                    <img src={"Logodos.jpg"} alt="imgLogo"/>
                </LogoImageWrapper>
                <br/>
                { msg && <Alert alert = {alert} />}
                <FormControl_Global
                    margintop='20px'
                >                    
                        <AddPrecipitationWrapper_Global_DOS
                             basis='50%'
                        >
                            <FormControl>
                                <FormControlInputSearch>
                                    <label>FINCA</label>                    
                                    <select id="sucursal"
                                        {...register("sucursal")}
                                            onChange={handleChange}
                                            tabIndex="1"
                                            value={sucursalSeleted}
                                            disabled={ auth.data.role != 1 && rdtprov.ibm != 90021?true:false}>
                                        <option value={""}>--Seleccione--</option>
                                            {sucursal.map(item=>(                                    
                                                    <option key={item.strCodigo} 
                                                            value={item.strCodigo}                                                                                               
                                                            id={item.strCodigo}                                                                                                      
                                                    >{item.strNombre}</option>
                                                    )
                                                    )
                                                }                                                                                                                                               
                                            </select>
                                </FormControlInputSearch>
                                <FormControlInputSearch>
                                            <label>FECHA</label>
                                            <input  type="date"
                                                    {...register("fechardt", { required: true })}
                                                    value={date} 
                                                    onChange={handleChangedate}
                                                    tabIndex="2"
                                            />
                                            {errors.fechardt?.type === 'required' && <span>Este Campo es requerido</span>}
                                </FormControlInputSearch>
                            </FormControl>
                        </AddPrecipitationWrapper_Global_DOS>
                        <AddPrecipitationWrapper_Global_DOS
                            basis='50%'
                        >
                            <FormControl>
                                <FormControlTitle>
                                    <label>{sucursalname}</label>
                                </FormControlTitle>
                                <FormControlTitle>
                                    <span>
                                    {nombreDia}
                                    <br/>                                     
                                    {date}
                                    </span>
                                </FormControlTitle>                                                             
                            </FormControl>
                        </AddPrecipitationWrapper_Global_DOS>                   
                </FormControl_Global>
                <FormControl_Global
                    margintop='5px'
                > 
                    <AddPrecipitationWrapper_Global_DOS>
                    <form>
                    <FormControl>
                            <FormControl>
                                <FormControlInputSearch>
                                    <label>MM LLUVIA</label>
                                    <input  type="number"                                    
                                        {...register("cantidad")}
                                            value={cantidad}
                                            onChange={e => setCantidad(e.target.value)}   
                                            autoFocus
                                            id="input1"                                                                                        
                                    />                                    
                                </FormControlInputSearch> 

                                <FormControlInputSearch>
                                    <FormControl>
                                        <Button label="GRABAR" onPress={handleSubmit(onSubmitsave)} />  
                                    </FormControl>                                                             
                                </FormControlInputSearch>                               
                            </FormControl>
                    </FormControl>
                    </form>
                    <PropertyTypesContainerRow>
                        <PropertyTypesContainerColum
                             margintop='10px'
                             marginleft='10px'
                             marginbotton='10px'
                        >
                            <label>Ultima Precipitación Reportada en la Fecha Consultada...</label>
                            {precipitation.map((item, key) => 
                                <PropertyPrecipitations
                                    key={key}                   
                                    {...item} 
                                    propertyTypeSeleted               
                                />
                                )
                            }
                        </PropertyTypesContainerColum>                        
                    </PropertyTypesContainerRow>
                    <PropertyTypesContainerRow>
                        <PropertyTypesContainerColum
                             margintop='10px'
                             marginleft='10px'
                             marginbotton='10px'
                        >
                             <label>Reportado en las Ultimas Semanas...</label>
                            {precipitationUltsem.map((item, key) => 
                                <PropertyPrecipitationsSem
                                    key={key}                   
                                    {...item} 
                                    propertyTypeSeleted               
                                />
                                )
                            }
                        </PropertyTypesContainerColum>                        
                    </PropertyTypesContainerRow>
                    </AddPrecipitationWrapper_Global_DOS> 
                    <AddPrecipitationWrapper_Global_DOS>
                       <label>mm x Dia {anio}</label>
                       <PropertyGrafLinealChart
                            sucursal={sucursalSeleted}
                            anio={anio}
                            semana={sem}
                       /> 
                       <br/>
                       <label>mm x Semana {anio}</label>
                       <PropertyGrafBarChart
                            sucursal={sucursalSeleted}
                            semana={sem}
                            anio={anio}
                       />
                       <br/>
                       <label>mm x mes {anio}</label>
                       <PropertyGrafBarChartmes
                            sucursal={sucursalSeleted}
                            semana={sem}
                            anio={anio}
                       />
                       <br/>
                       <label>mm x Año</label>
                       <PropertyGrafDonaChartanio
                            sucursal={sucursalSeleted}                            
                            anio={anio}
                       />                 
                    </AddPrecipitationWrapper_Global_DOS>                                     
                </FormControl_Global>                                    
            </AddPrecipitationWrapper_Global_DOS>
        </AddPrecipitationWrapper_Global>      
    </Page>
  )
}
