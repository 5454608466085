import { useNavigate } from "react-router-dom";
import { useRdt } from "../../../../hooks/useRdt";
import { SubTitle } from "../../../../globalStyles"
import { PropertyButtonWrapper, PropertyCardWrapper, 
    PropertyCardWrapperTr, 
    PropertyDescriptionWrapper, 
    PropertyImageWrapper, 
    PropertyInfoWrapper, 
    PropertyValueWrapper } from "./styles"
import { ButtonIconRemove } from "../../../../components/ButtonIconRemove"
import { IoTrashOutline, 
        IoRemoveCircleOutline,
        IoRadioButtonOffOutline,
        IoArrowBackCircleOutline,
        IoArrowBack,
        IoDuplicate,
        IoAddCircleOutline,
        IoSave, 
        IoSend} from "react-icons/io5";
import swal from 'sweetalert';
import { HTTP_VERBS, requestHttp } from "../../../../utils/HttpRequest";
import { getToken } from "../../../../utils/TokenLS";
import { showAlert, SW_ICON } from "../../../../utils/swAlert";

export const PropertyRdts = (item) =>{   

    const { setReload } = useRdt();

    const navigate = useNavigate();

    //Validar dia de la semana
    const fechaComoCadena = item.dtmFecha_RDT.split('T')[0] +  " 00:01:59"; // día semana
    const dias = [
        'DOMINGO',
        'LUNES',
        'MARTES',
        'MIERCOLES',
        'JUEVES',
        'VIERNES',
        'SABADO',
    ];
    const numeroDia = new Date(fechaComoCadena).getDay();
    const nombreDia = dias[numeroDia];

    const fecha = new Date(fechaComoCadena);
    //hoy
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);

    const rango = new Date(hoy - fecha);
    
    //Delete Rdt
    const showAlertYN = (id) =>{
        //console.log({id: id})
        //Vallido que si este dentro de un rango permitido para eliminar
        //Validaciones
        //Vallido que si este dentro de un rango permitido para registrar
        //valido si dia es viernes o sabado                          
        if(numeroDia >= 5){
            //rango para el viernes de 4 dias.
            if(numeroDia == 5){                
                if(rango > 363599999){
                    showAlert('Error', "Registro esta fuera del tiempo permitido para eliminar", SW_ICON.ERROR);      
                    return
                }
            }else{
                //rango para el sabado de 3 dias.                     
                    if(rango > 276999999){
                    //if(rango > 363599999){ //Festivo dia lunes
                        showAlert('Error', "Registro esta fuera del tiempo permitido para eliminar", SW_ICON.ERROR);             
                        return
                    }
            }
        }else{
            //Rango de 2 dias para registrar
            if(rango > 189999999){                
                showAlert('Error', "Registro esta fuera del tiempo permitido para eliminar", SW_ICON.ERROR);  
                return
            }
        }        
        swal({
            title: "Esta usted seguro?",
            text: "Una vez eliminado, no podras recuperar este registro!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {   
                rdtDelete(id)                
            } else {
            //swal("Su registro esta a salvo!");
            return
            }
            //console.log(willDelete)
        });
    }
    const rdtDelete = async (id) =>{             
        try {           
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.DELETE,
                    endpoint: `/rdts/${id}`,               
                    token: getToken()               
                }
            );
            setReload(true)   
            showAlert(
                'RDT', 
                "Eliminado Correctamente", 
                SW_ICON.SUCCESS,   
                //() => { navigate('/AddRdtsDistribution')}                 
            );                              
                
            } catch (error) { 
                if(error.response.status === 401){
                    //hendleCloseSeccion();
                }else{
                    showAlert('Error', "Eliminación Incorrecta", SW_ICON.ERROR);
                }            
            }        
    } 

    return (
    
        <PropertyCardWrapperTr>
            <PropertyCardWrapper to={`/RdtDetail/${item._id}`}>               
                <PropertyInfoWrapper>
                    {item.strNombreTrabajador} 
                        <SubTitle>
                            <span>{item.IntCodigo_EMPL} </span>&nbsp;{item.strNombre_SUCU}&nbsp;&nbsp;<span>Horas:&nbsp;{item.intHora_RDT}</span>&nbsp;  
                            <span>Labor:&nbsp;{item.IntCodigo_CONC}</span>&nbsp;<span>Total:&nbsp;{(item.intTotal_RDT).toFixed(2)}</span>&nbsp; 
                            ({item.strNombre_UDP}:&nbsp;<span>{item.intCantidad_UDP}</span>&nbsp;
                            {item.strNombre_UDPdos}:&nbsp;<span>{item.intCantidad_UDPdos}</span>&nbsp;
                            {item.strNombre_UDPtres}:&nbsp;<span>{item.intCantidad_UDPtres}</span>&nbsp;
                            {item.strNombre_UDPcuatro}:&nbsp;<span>{item.intCantidad_UDPcuatro}</span>&nbsp;)
                            {item.strObservacion_RDT}
                        </SubTitle>
                </PropertyInfoWrapper>               
            </PropertyCardWrapper>
            <PropertyButtonWrapper>
                <ButtonIconRemove icon={IoTrashOutline} onPress={()=>showAlertYN(item._id)} />
            </PropertyButtonWrapper>
        </PropertyCardWrapperTr>    
          
          
    )
}