import { useEffect, useState } from 'react'
import { useAuth } from '../../../../hooks/useAuth';
import { useRdt } from '../../../../hooks/useRdt';
import { getToken, removeToken } from '../../../../utils/TokenLS';
import { showAlert, SW_ICON } from '../../../../utils/swAlert';
import { IoCloseCircleOutline, IoBookmark } from "react-icons/io5";
import { AnalysesPropertyTypesContainerColum, 
         AnalysesPropertyTypesContainerRow, 
         ButtonAplicate, 
         CerrarAnalysesRdt, 
         FormControlAnalysesRdt, 
         FormularioAnalysesRdt, 
         PropertyTable, 
         PropertyTableCell, 
         PropertyTableCellFonts, 
         PropertyTableContainer, 
         PropertyTableHeader, 
         PropertyTableHeaderRow, 
         PropertyTableRow, 
         TittleAnalysesRdt } from './styles';
import { ButtonIcon } from '../../../../components/ButtonIcon';
import { HTTP_VERBS, requestHttp } from '../../../../utils/HttpRequest';
import { ButtonIconRibbon } from '../../../../components/ButtonIconRibbon';
import { Colors } from 'chart.js';
import { ModalLoteGuan } from '../ModalLoteGuan';
import { Button } from '../../../../components/Button';
import { Buttoninv } from '../../../../components/Buttoninv';
import { ButtonIconRibbonEmb } from '../../../../components/ButtonIconRibbonEmb';


const colors = [ 
    {id:1, cinta:'brown', name:'CAFE', nameCorto:'CA'},
    {id:2, cinta:'black', name:'NEGRA', nameCorto:'NE'}, 
    {id:3, cinta:'orange', name:'NARANJA', nameCorto:'NA'}, 
    {id:4, cinta:'green', name:'VERDE', nameCorto:'VE'}, 
    {id:5, cinta:'yellow', name:'AMARILLA', nameCorto:'AM'},   
    {id:6, cinta:'white', name:'BLANCA', nameCorto:'BL'},
    {id:7, cinta:'blue', name:'AZUL', nameCorto:'AZ'},
    {id:8, cinta:'gray', name:'GRIS', nameCorto:'GR'},   
    {id:9, cinta:'purple', name:'MORADA', nameCorto:'MO'},    
    {id:10, cinta:'red', name:'ROJA', nameCorto:'RO'},   
];

export const DetailAnalysesRdtsInvGua = ({ibm,fechafin,codLabor, codLaborGua, global, sucursalName}) => {
    //console.log({ibm, codLabor,codLaborGua,global: global, sucursalName})
    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, reload, setReload, rdtEmploye  } = useRdt();

    //Modal para mostrar el detalle de las personas que realizarón la labor 119 Colocar Guantelete y 451 Cuello monja racimo.
    const [ modalLoteGuan, setModalLoteGuan ] = useState(false);    
    const [ animarModalLoteGuan, setAnimarModalLoteGuan ] = useState(false);
    const [ idCinta, setIdCinta ] = useState(null);
    const [ idLote, setIdLote ] = useState(null);
    const [ embLote, setEmbLote ] = useState(null);
    const [ guaLote, setGuaLote ] = useState(null);


    //Buscar en calendario según fecha que se esta trabajando
    const [ calendar, setCalendar ] = useState([]);
    //Buscar analisis de rdts por cinta,lotes para embolse
    const [ rdtsudps, setRdtsudps ] = useState([]);
    //Buscar analisis de rdts por cinta,lotes para Guantelete
    const [ rdtsudpsGua, setRdtsudpsGua ] = useState([]);

    const [ mostrarData, setMostrarData ] = useState({});
    
    //Para sombrear cuando paso el mouse sobre la celda.
    const [hoveredCell, setHoveredCell] = useState(null);
    
    const handleMouseOver = (rowIndex, colIndex) => {
        setHoveredCell({ rowIndex, colIndex });
        //console.log(rowIndex, colIndex)
    };
    const handleMouseOut = () => {
        setHoveredCell(null);
    };
    
    //Token valido o no expirado
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token){
                    
                } return                  
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[auth])
    
    //Calendar       
    useEffect(() => {
        const getData = async ()=>{
            //console.log('obtener calendar');
            try {
                const token = getToken();
                if(!token) return                
                //await  cleanStates();                
                await getCalendar();                
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();         
              
    },[ibm,fechafin,codLabor, codLaborGua])
    //rdtsUdps       
    useEffect(() => {
        const getData = async ()=>{
            //console.log('obtener calendar');
            try {
                const token = getToken();
                if(!token) return                
                //await  cleanStates();                               
                await getRdtsUdp();
                await getRdtsUdpGua();
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();         
              
    },[calendar])
    //getCalendars para prematuro y presente
    const getCalendar = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/calendars',                         
                    params: makePropertiesFiltersCalendar()                                     
                }
            );             
            const {data} = response;                
            setCalendar(data); 
            //console.log({calendar: data});       
            //console.log(calendar[0].cintaPm);    
            //console.log(calendar[0].cintaPs);                 
        } catch (error) {
            if(error.response.status === 401){
                
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersCalendar = ()=>{
        const filters = {};
            //Para obtener el calendario de las ult 6 sem de edad para embolses y Guantelete                     
            filters['Fecha_FIN_INV_GUA'] = fechafin; 
            filters['tipo'] = 'udps';                          
            //filters['employeesId'] = auth.data._id;              
            //console.log(fechaini);
        return filters;       
    }
    //getRdts x udp x cinta para embolse
    const getRdtsUdp = async ()=>{      
        //console.log('haciendo la consulta de rdtsudps') 
        //console.log({filters: calendar[0].fini}); 
        if(!calendar[0]){
            setRdtsudps([])
            //console.log('Fecha Ini vacia')
            return
        }                            
        if(!fechafin){
            setRdtsudps([])
            //console.log('Fecha vacia')
            return                              
        }else{
            
          try {  
                              
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/Rdts/analysesudps',                                               
                    params: makePropertiesFilters()                                     
                }
            );             
            const {data} = response;                
            data?setRdtsudps(data):console.log('');
            //console.log({rdtsUdps: data})        
            } catch (error) {
                if(error.response.status === 401){                    
                    
                }else{
                    //console.log('ErrorRdtsUdps')
                    console.log(error)
                }
            }
              //console.log({hacerConsulta: sucursalSeleted})        
        }                   
    }
    const makePropertiesFilters = ()=>{
        const filters = {}; 
            //console.log({filtersIni: 'Iniciando'});
            //filters['Fecha_INI'] = '2024-02-17';//Necesitamos organizar el calendario por la fini mas menor.
            const fechaMenor = calendar.reduce((min, actual) => {
                return new Date(actual.fini) < new Date(min.fini) ? actual : min;
            });
            //console.log({fechaMenor: fechaMenor.fini.split('T')[0]});              
            //filters['Fecha_INI'] = calendar[0].fini.split('T')[0];            
            filters['Fecha_INI'] = fechaMenor.fini.split('T')[0];
            filters['Fecha_FIN'] = fechafin; 
            filters['IbmLaboro'] = ibm;   
            filters['IntCodigo_CONC'] = codLabor;
            filters['tipo'] = 'inv';            
        // filters['employeesId'] = auth.data._id;     
        //console.log({filters: calendar[0].fini.split('T')[0]});
        //console.log({filters: filters});
        
        return filters;       
    }
    //getRdts x udp x cinta para Guantelete
    const getRdtsUdpGua = async ()=>{      
        //console.log('haciendo la consulta de rdtsudps') 
        //console.log({filters: calendar[0].fini}); 
        if(!calendar[0]){
            setRdtsudpsGua([])
            //console.log('Fecha Ini vacia')
            return
        }                            
        if(!fechafin){
            setRdtsudpsGua([])
            //console.log('Fecha vacia')
            return                              
        }else{
            
          try {  
                              
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/Rdts/analysesudps',                                               
                    params: makePropertiesFiltersGua()                                     
                }
            );             
            const {data} = response;                
            data?setRdtsudpsGua(data):console.log('');
            //console.log({rdtsUdpsGua: data})        
            } catch (error) {
                if(error.response.status === 401){                    
                    
                }else{
                    //console.log('ErrorRdtsUdps')
                    console.log(error)
                }
            }
              //console.log({hacerConsulta: sucursalSeleted})        
        }                   
    }
    const makePropertiesFiltersGua = ()=>{
        const filters = {}; 
            //console.log({filtersIni: 'Iniciando'});
            //filters['Fecha_INI'] = '2024-02-17';
            //Necesitamos organizar el calendario por la fini mas menor.
            const fechaMenor = calendar.reduce((min, actual) => {
                return new Date(actual.fini) < new Date(min.fini) ? actual : min;
            });
            //console.log({fechaMenor: fechaMenor.fini.split('T')[0]});              
            //filters['Fecha_INI'] = calendar[0].fini.split('T')[0];            
            filters['Fecha_INI'] = fechaMenor.fini.split('T')[0];            
            filters['Fecha_FIN'] = fechafin; 
            filters['IbmLaboro'] = ibm;   
            filters['IntCodigo_CONC'] = codLaborGua;
            //filters['tipo'] = 'inv';            
        // filters['employeesId'] = auth.data._id;     
        //console.log({filters: calendar[0].fini.split('T')[0]});
        //console.log({filters: filters});
        
        return filters;       
    }
    //1. Eliminamos los objetos con totalCantidad igual a cero para embolse y Guantelete
    const rdtsudpsuno = rdtsudps.filter(objeto => objeto.totalCantidad !== 0 );    
    const rdtsudpsdoss = rdtsudpsGua.filter(objeto => objeto.total !== 0 );       
    const rdtsudpsdos = rdtsudpsdoss.filter(objeto => objeto.cinta !== undefined );       
    //console.log({rdtsudps: rdtsudps })
    //console.log({rdtsudpsuno: rdtsudpsuno })
    //console.log({rdtsudpsdos: rdtsudpsdos })    
    //2. Le hago un distinc a global y saco todos los lotes de la finca
    const Lotes = [...new Set(global.map((itemLote) => itemLote.Lote))];
    //console.log({Lotes: Lotes})
    //3. Le hago un distinc a calendar a las semanas
    const Semanas = [...new Set(calendar.map((itemSem) => itemSem.semana))];
    //console.log({Semanas: Semanas})
    //4. Le hago un distinc a calendar a las cintaPm
    const Cintas = [...new Set(calendar.map((itemSem) => itemSem.cintaPm))];
    //console.log({Cintas: Cintas})    
    //5. Agrego a las cintas el color de la cinta
    const cintasColors = [];
    //5. Iterar sobre Cintas y agregar el nombre de la cinta en el arreglo cintasColors
    Cintas.forEach(itemDos => {        
        //cintasColors = [{codCinta: itemDos, color:colors.id[itemDos].name}]    
        //console.log({itemDos:itemDos}) 
        //console.log({ColorsName: colors.name})
        const color = colors.find(color => color.id == itemDos)
        if(color){
          //cintaPs = color.cinta
          //nameCintaPs = color.name
          //console.log({ColorsName: color.name})
         const sem = calendar.find(cinta=>cinta.cintaPm === itemDos)
          //console.log({semCal: sem.semana})
          cintasColors.push({
            id: itemDos,
            cintaName: color.name,
            semana: sem.semana,
            orden: cintasColors.length
          })
        }
                
    });
    //console.log({Colors: colors})
    //console.log({cintasColors: cintasColors})    
    //Ordenar las cintas en forma descendente
    cintasColors.sort((a, b) => b.orden - a.orden);
    //6. Crear un nuevo arreglo para almacenar los resultados de rdtsudpsuno y rdtsudpsdos
    const nuevoArreglo = [];
    const nuevoArregloDos = [];
    //6.1 Crear un conjunto para rastrear las combinaciones únicas de lote, cinta rdtsudpsuno y rdtsudpsdos
    const combinacionesUnicas = new Set();
    const combinacionesUnicasDos = new Set();
    //6.2 Iterar sobre los datos de rdtsudpsuno originales y guardar un nuevoArreglo rdtsudpsuno y rdtsudpsdos
    for (const cantidadLotes of rdtsudpsuno) {
        const { Lote, cinta, totalCantidad } = cantidadLotes; //Campos de rdtsudpsuno
        const combinacion = `${Lote}-${cinta}`;

        if (!combinacionesUnicas.has(combinacion)) {
            combinacionesUnicas.add(combinacion);//Guardo las combinaciones unicas de lote-cinta
        }
        
        //Guardo los datos de rdtsudpsuno solo de las combinaciones encontradas por lote-cinta en nuevoArreglo
        nuevoArreglo.push({                        
            Lote,
            cinta,            
            totalCantidad                     
        });
        
    }
    //console.log({combinacionesUnicas: combinacionesUnicas}) //Embolse
    //console.log({nuevoArreglo: nuevoArreglo}) //Embolse
    for (const cantidadLotesDos of rdtsudpsdos) {
        const { Lote, cinta, total } = cantidadLotesDos; //Campos de rdtsudpsdos
        const combinacion = `${Lote}-${cinta}`;

        if (!combinacionesUnicasDos.has(combinacion)) {
            combinacionesUnicasDos.add(combinacion);//Guardo las combinaciones unicas de lote-cinta
        }
        
        //Guardo los datos de rdtsudpsdos solo de las combinaciones encontradas por lote-cinta en nuevoArregloDos
        nuevoArregloDos.push({                        
            Lote,
            cinta,            
            total                     
        });
        
    }
    //console.log({combinacionesUnicasDos: combinacionesUnicas}) //Guantelete
    //console.log({nuevoArregloDos: nuevoArregloDos}) //Guantelete

    //7. Completar con los lotes faltantes por cinta para luego ser visualizados tanto en Embolse y Guantelete
    //7.1 Recorro las cintas primero
    cintasColors.forEach(itemCinta=>{
        //console.log({itemCinta:itemCinta})
        //7.2 Luego los lotes 
        Lotes.forEach(itemLote=>{
            //console.log({itemLotes: itemLote, itemCinta:itemCinta})
            //7.3 por ultimo comparo con nuevoArreglo si tiene lotes y cintas iguales y alamaceno resultado en encontre y agrego a nuevo arreglo los que no estan en Embolse
            const encontre = nuevoArreglo.find(itemNA=>itemNA.Lote === parseInt(itemLote) && itemNA.cinta === itemCinta.cintaName)
            if(!encontre){
                nuevoArreglo.push({                                       
                    Lote: parseInt(itemLote),
                    cinta: itemCinta.cintaName,                    
                    totalCantidad: 0                    
                });
                //console.log({itemNA: encontre})
            }
            
            //7.3 por ultimo comparo con nuevoArreglo si tiene lotes y cintas iguales y alamaceno resultado en encontre y agrego a nuevo arreglo los que no estan en Embolse
            const encontreDos = nuevoArregloDos.find(itemNA=>itemNA.Lote === parseInt(itemLote) && itemNA.cinta === itemCinta.cintaName)
            if(!encontreDos){
                nuevoArregloDos.push({                                       
                    Lote: parseInt(itemLote),
                    cinta: itemCinta.cintaName,                    
                    total: 0                    
                });
                //console.log({itemNA: encontre})
            }
        })
        
    })   
    //console.log({nuevoArreglo: nuevoArreglo})
    //console.log({nuevoArregloDos: nuevoArregloDos})

    //Mostrar
    //8. Función para organizar los elementos por cinta y lote para embolses
    const analisisOrganizado = {};
    const analisisColors = {};
    const analisisLotes = {};
    const analisisTotales = {};
    //8. Función para organizar los elementos por cinta y lote para guantelete
    const analisisOrganizadoDos = {};
    const analisisTotalesDos = {};

    let totalNeta = 0;
    let totalBruta = 0;

    const organizarPorSemanaYLote =  () => {        
        //Embolse
        nuevoArreglo.forEach(item => {                         
            // Si la semana no está en el objeto, inicializarla
            if (!analisisOrganizado[item.Lote]) {
                analisisOrganizado[item.Lote] = {};
            }
            // Si el lote no está en la semana, inicializarlo
            if (!analisisOrganizado[item.Lote][item.cinta]) {
                analisisOrganizado[item.Lote][item.cinta] = { totalCantidad: parseInt(item.totalCantidad) };
            }

            // Si la cinta no esta, inicializarlo
            if (!analisisTotales[item.cinta]) {
                analisisTotales[item.cinta] = {cantCinta:0};
            }
            // Agregar la suma de totalCantidad a la nueva columna cantCinta
            analisisTotales[item.cinta].cantCinta += item.totalCantidad;
                       
        });            
        //console.log({analisisOrganizado:analisisOrganizado})
        //console.log({analisisTotales:analisisTotales})
        
        //Guantelete
        nuevoArregloDos.forEach(item => {                         
            // Si la semana no está en el objeto, inicializarla
            if (!analisisOrganizadoDos[item.Lote]) {
                analisisOrganizadoDos[item.Lote] = {};
            }
            // Si el lote no está en la semana, inicializarlo
            if (!analisisOrganizadoDos[item.Lote][item.cinta]) {
                analisisOrganizadoDos[item.Lote][item.cinta] = { total: parseInt(item.total) };
            }

            // Si la cinta no esta, inicializarlo
            if (!analisisTotalesDos[item.cinta]) {
                analisisTotalesDos[item.cinta] = {cantCinta:0};
            }
            // Agregar la suma de total a la nueva columna cantCinta
            analisisTotalesDos[item.cinta].cantCinta += item.total;
                       
        });
        //console.log({analisisOrganizadoDos:analisisOrganizadoDos})
        //console.log({analisisTotalesDos:analisisTotalesDos})

        //Para el color de las cintas en los encabezados
        colors.forEach(item=>{
            analisisColors[item.name] = {cinta: item.cinta, nameCorto: item.nameCorto}
        })
        //console.log({analisisColors:analisisColors}) 
        //Para la suma de areas netas y brutas + totales        
        global.forEach(item=>{
            analisisLotes[item.Lote] = {aneta: item.A_Neta, abruta: item.A_Bruta}
            //console.log({item: item})
            totalNeta += item.A_Neta
            totalBruta += item.A_Bruta
        }) 
        //console.log({totalNeta: totalNeta})
        //console.log({totalBruta: totalBruta})
        //console.log({analisisLotes:analisisLotes})   


    };    
    
    // Función para renderizar las tablas por semana
    const renderTablasPorSemana = () => {
        organizarPorSemanaYLote();        
    };

    // Función para mostrar el modal para el detalle del guantelete.
    const handleModalGuanLote = (idDetail, idLote, embLote, guaLote)=>{
        //console.log({idDetail: idDetail})
        //console.log({idLote: idLote})
        //console.log({embLote: embLote})
        //console.log({guaLote: guaLote})  
        setModalLoteGuan(true)
        setIdCinta(idDetail);
        setIdLote(idLote);
        setEmbLote(embLote);
        setGuaLote(guaLote);
        //console.log({modal: modalLoteGuan})  
        setTimeout(() => {
          setAnimarModalLoteGuan(true)
        }, 300);
       
    }

    //<ButtonIconRibbonEmb icon={IoBookmark} cinta={analisisColors[cinta.cintaName].cinta} onPress={()=>handleModalGuanLote(cinta, lote, (analisisOrganizado[lote][cinta.cintaName].totalCantidad), (analisisOrganizadoDos[lote][cinta.cintaName].total) )}></ButtonIconRibbonEmb>

  return (
        <div>
        <h4>{sucursalName} &nbsp;&nbsp; Embolses y Guanteletes digitados en los RDTs en las Ult 7 Semanas</h4>        
        
        <PropertyTable>            
                {renderTablasPorSemana()}            
            <thead>
            {modalLoteGuan ? '':    
                <PropertyTableRow>
                    <PropertyTableHeader>LOTE</PropertyTableHeader>
                    <PropertyTableHeader>A_BRUTA</PropertyTableHeader>
                    <PropertyTableHeader>A_NETA</PropertyTableHeader>                    
                    {cintasColors.map(cinta=>(                         
                        <PropertyTableHeader>
                            <PropertyTableHeader>SM {cinta.semana}</PropertyTableHeader>
                            {analisisColors[cinta.cintaName].nameCorto}                                                       
                            <ButtonIconRibbon icon={IoBookmark} cinta={analisisColors[cinta.cintaName].cinta} />
                            <PropertyTableHeader>EMBO_GUANT</PropertyTableHeader>
                        </PropertyTableHeader>                        
                    ))}
                </PropertyTableRow>
            }
            </thead>
            
            <tbody>
                {Lotes.map(lote=>(
                    <PropertyTableRow key={lote}>
                        <PropertyTableHeader>{lote}</PropertyTableHeader>
                        <PropertyTableCell>{analisisLotes[lote].abruta}</PropertyTableCell>
                        <PropertyTableCell>{analisisLotes[lote].aneta}</PropertyTableCell>
                        {cintasColors.map(cinta=>(
                                
                                <PropertyTableCell key={`${lote}_${cinta.cintaName}`}
                                    onMouseOver={() => handleMouseOver(lote, cinta.cintaName)}
                                    onMouseOut={handleMouseOut}
                                    style={{
                                    
                                        backgroundColor: hoveredCell &&
                                        hoveredCell.rowIndex === lote &&
                                        hoveredCell.colIndex === cinta.cintaName ? '#f0a808' : '',
                                        color: hoveredCell &&
                                        hoveredCell.rowIndex === lote &&
                                        hoveredCell.colIndex === cinta.cintaName ? 'white' : ''
                                    
                                    }}
                                    onClick={()=>handleModalGuanLote(cinta, lote, (analisisOrganizado[lote][cinta.cintaName].totalCantidad), (analisisOrganizadoDos[lote][cinta.cintaName].total) )}    
                                >
                                    
                                    {analisisOrganizado[lote][cinta.cintaName].totalCantidad} - {analisisOrganizadoDos[lote][cinta.cintaName].total}&nbsp;                                                                                                 
                                     ({(analisisOrganizado[lote][cinta.cintaName].totalCantidad-analisisOrganizadoDos[lote][cinta.cintaName].total)>0?
                                            <PropertyTableCellFonts color={'blue'}>{analisisOrganizado[lote][cinta.cintaName].totalCantidad-analisisOrganizadoDos[lote][cinta.cintaName].total}</PropertyTableCellFonts>:
                                            (analisisOrganizado[lote][cinta.cintaName].totalCantidad-analisisOrganizadoDos[lote][cinta.cintaName].total)==0?
                                            <PropertyTableCellFonts color={'black'} fontw={'bold'}>{analisisOrganizado[lote][cinta.cintaName].totalCantidad-analisisOrganizadoDos[lote][cinta.cintaName].total}</PropertyTableCellFonts>:
                                            <PropertyTableCellFonts color={'red'} fontw={'bold'}>{analisisOrganizado[lote][cinta.cintaName].totalCantidad-analisisOrganizadoDos[lote][cinta.cintaName].total}</PropertyTableCellFonts>})                                                                                                                                               
                                </PropertyTableCell>
                                
                        ))}
                    </PropertyTableRow>
                ))}
            </tbody>
            <tfoot>
                <PropertyTableRow>
                    <PropertyTableHeader>TOTAL</PropertyTableHeader>
                    <PropertyTableHeader>{(totalBruta).toFixed(2)}</PropertyTableHeader>                    
                    <PropertyTableHeader>{(totalNeta).toFixed(2)}</PropertyTableHeader>                    
                    {cintasColors.map(cinta=>(
                        <PropertyTableHeader>
                            {analisisTotales[cinta.cintaName].cantCinta } - {analisisTotalesDos[cinta.cintaName].cantCinta } ({analisisTotales[cinta.cintaName].cantCinta-analisisTotalesDos[cinta.cintaName].cantCinta})
                        </PropertyTableHeader>
                    ))}                                 
                </PropertyTableRow>
            </tfoot>
        </PropertyTable>
        
        {modalLoteGuan && <ModalLoteGuan
                    setModalLoteGuan={setModalLoteGuan}
                    animarModalLoteGuan={animarModalLoteGuan}
                    detail={idCinta}
                    lote={idLote}
                    calendario={calendar}
                    fechafin={fechafin}
                    embLote={embLote}
                    guaLote={guaLote}
                    global={global}
                    ibm={ibm}
                    sucursalName={sucursalName}/>                                            
         }
        </div>
  )
}