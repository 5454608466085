import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { Combobox } from "@headlessui/react";
import { Page } from "../../components/Page"
import { FormControl, 
         FormControlAction,         
         FormControlInput, 
         PageTitle, 
         FormControlInputSearch,
         FormControlInputRadio} from "../../globalStyles";
import { Button } from "../../components/Button";
import { ButtonIcon } from "../../components/ButtonIcon";
import { IoSearchCircleSharp } from "react-icons/io5";
import { AddNewsBusqueda, AddNewsCombo, AddNewsWrapper_Global, 
         AddNewsWrapper_Global_DOS, 
         AddNewsWrapper_Global_TRES} from "./styles";
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";
import { getToken, removeToken } from "../../utils/TokenLS";
import { PropertyCard } from "../Home/components/PropertyCard";

const ALL_SUCURSALES = 0;

export const AddNews = () =>{
        
    const { auth, closeSeccionAuth } = useAuth();
    const [ sucursal, setSucursal ] = useState([]);
    const [ centros, setCentros ] = useState([]);
    const [ employe, setEmploye ] = useState([]);
    const [ employees, setEmployees ] = useState([]);

    const [sucursalSeleted, setSucursalSelected] = useState(ALL_SUCURSALES);
    const [centrosSeleted, setCentrosSelected] = useState(ALL_SUCURSALES);
    const [employeSeleted, setEmployeSelected] = useState();
    const [codigoSeleted, setCodigoSelected] = useState();
    const [ motivoSeleted, setMotivoSelected ] = useState('');

    const [ busqueda, setBusqueda] = useState('')
    const [ traslate, setTraslate ] = useState([]);
    
    
    const navigate = useNavigate();

    const { 
            register, 
            handleSubmit,
            formState:{
                errors,
                isValid
            }  
    } = useForm({ mode: 'onChange'});     
 
    //Close session if token expired
    const hendleCloseSeccion = async ()=>{
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Inicie Seccion Nuevamente', 
            "Su token puede estar vencido", 
            SW_ICON.INFO,
            () => { navigate('/Login')}    
        );
    }
    
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return                
                await getSucursalesAll();               
                await getEmployeesAll();  
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();   
              
    },[auth])   
    //Verificar estado empleado lleno y limpiar busqueda
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                await getVerificarEmploye();                           
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[employe])

    //Motivo
    const handleChangeMotivo = event => {
        setMotivoSelected(event.target.value)      
        //console.log(motivoSeleted)                 
     }
    //Sucursales
    const handleChange = event => {
        setSucursalSelected(event.target.value)                  
    } 
    const getSucursalesAll = async ()=>{
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/sucursal',                         
                    params: makeSucursalesFilters()                                     
                }
            );             
            const {data} = response;                
            setSucursal(data);                                   
        } catch (error) {
            console.log(error)
        }
    }
    const makeSucursalesFilters = ()=>{
        const filters = {};           
        return filters;       
    }    
    //Centros       
    useEffect(() => {
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return
                await  getCentros();  
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();         
              
     },[sucursalSeleted])
    
    const handleChangeC = event => {
        setCentrosSelected(event.target.value)                      
    } 
    const getCentros = async ()=>{
        
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/centros',                         
                    params: makeCentrosFilters()                                     
                }
            );             
            const {data} = response;                
            setCentros(data);                                              
        } catch (error) {
            console.log(error)
        }
    }  
    const makeCentrosFilters = ()=>{
        const filters = {};  
        if(sucursalSeleted !== ALL_SUCURSALES){
            filters['strCodigoSucursal'] = sucursalSeleted;
        }
        //console.log(filters);         
        return filters;            
    }
    //EmployeesDoc
    const handleEmployeDoc = () => { 
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return
                if((auth.data.role !== 1) && (auth.data.role !== 2) && (auth.data.role !== 3)){
                    showAlert(
                        'No Autorizado', 
                        "Usuario no permitido para realizar esta operación", 
                        SW_ICON.INFO                
                    ); 
                    return 
                }
                await  getEmployeesDoc();   
                setCodigoSelected('');
            } catch (error) {
                console.log(error) 
            }             
        }    
        getData();                
    }
    const getEmployeesDoc = async ()=>{
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/employees',                         
                    params: makeEmployeesFiltersDoc()                                     
                }
            );             
            const {data} = response;              
            setEmploye(data);             
            //Valido si existe 
            if(!data.length){
                showAlert('Error', "Identificación no existe", SW_ICON.ERROR);   
            }
            //Validar si el trabajador esta vinculado a la finca del usuario que realiza el traslado.            
            if((auth.data.role === 3) && (data[0].Ibm !== auth.data.ibm)){
                showAlert(
                    'No Autorizado', 
                    `Trabajador CC.${employeSeleted} no pertenece a su finca`, 
                    SW_ICON.INFO);   
                    setEmploye('');
            }
            setEmployeSelected('');           
            setCodigoSelected('');                      
                                   
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }                       
        }
    }        
    const makeEmployeesFiltersDoc = ()=>{
        const filters = {};  
        if(employeSeleted !== ALL_SUCURSALES){
            filters['strDocumento'] = employeSeleted;
        }
        //console.log(filters);         
        return filters;       
    }
    //EmployeesCod
    const handleEmployeCod = () => {   
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return
                if((auth.data.role !== 1) && (auth.data.role !== 2) && (auth.data.role !== 3)){
                    showAlert(
                        'No Autorizado', 
                        "Usuario no permitido para realizar esta operación", 
                        SW_ICON.INFO                
                    ); 
                    return 
                }
                await   getEmployeesCod();       
                setEmployeSelected(''); 
            } catch (error) {
                if(error.response.status === 401){
                    hendleCloseSeccion();
                }else{
                    console.log(error)
                }
            }             
        }    
        getData();                                
    }
    const getEmployeesCod = async ()=>{
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/employees',                         
                    params: makeEmployeesFiltersCod()                                     
                }
            );             
            const {data} = response;              
            setEmploye(data); 
            //Valido si existe 
            if(!data.length){
                showAlert('Error', "Identificación no existe", SW_ICON.ERROR);   
            }
            //Validar si el trabajador esta vinculado a la finca del usuario que realiza el traslado.            
            if((auth.data.role === 3) && (data[0].Ibm !== auth.data.ibm)){
                showAlert(
                    'No Autorizado', 
                    `Trabajador con Codigo: ${codigoSeleted} no pertenece a su finca`, 
                    SW_ICON.INFO);   
                    setEmploye('');
            }
            setEmployeSelected('');           
            setCodigoSelected('');  
           
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }  
        }
    }
    const makeEmployeesFiltersCod = ()=>{
        const filters = {};  
        if(codigoSeleted !== ALL_SUCURSALES){
            filters['IntCodigo'] = codigoSeleted;
        }
        //console.log(filters);         
        return filters;       
    }
    //EmployeesAllnombre     
    const getEmployeesAll = async ()=>{        
            try {            
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/employees',
                        params: makeEmployeesFiltersAll()                                      
                    }
                );             
                const {data} = response;              
                setEmployees(data); 
            } catch (error) {
                if(error.response.status === 401){
                    hendleCloseSeccion();
                }else{
                    console.log(error)
                }  
            }        
    }
    const makeEmployeesFiltersAll = ()=>{
        const filters = {}; 

        if((auth.data.role !== 1) && (auth.data.role !== 2)){
            filters['Ibm'] = auth.data.ibm;
        }
        //console.log(filters);         
        return filters;       
    }
    const getVerificarEmploye = async ()=>{
        const resultado = employe?setBusqueda(''):''
        const resultadoDos = employe?getTraslate():''
    }
    //TraslateById
    const getTraslate = async ()=>{
        //console.log({id: employe[0].IntCodigo}) 
        try {                      
        const response = await requestHttp(
            {
                    method: HTTP_VERBS.GET,
                    endpoint: `/traslates`, 
                    params: makeEmployeesFiltersCodigoEmpl()                                                                      
            }
        );             
        const {data} = response; 
        console.log({data: data})                
        setTraslate(data);                   
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            } 
        }
    
    }
    const makeEmployeesFiltersCodigoEmpl = ()=>{
        const filters = {}; 
        filters['IntCodigo'] = employe[0].IntCodigo;
        console.log(filters);         
        return filters;       
    }
    //Traslate
    const onSubmitTraslate = (data) =>{            
        //console.log(auth)
        const dataTraslate = {}
        dataTraslate['idTraslado'] =  employe[0].idTrabajador
        dataTraslate['IntCodigo'] =  employe[0].IntCodigo
        dataTraslate['strDocumento'] =  employe[0].strDocumento
        dataTraslate['strTipoTrabajador'] =  employe[0].strTipoTrabajador
        dataTraslate['strNombreTrabajador'] =  employe[0].strNombreTrabajador
        dataTraslate['strSexo'] =  employe[0].strSexo
        dataTraslate['dtFNacimiento'] =  employe[0].dtFNacimiento
        dataTraslate['strEstadoCivil'] =  employe[0].strEstadoCivil
        dataTraslate['dtFIngreso'] =  employe[0].dtFIngreso
        dataTraslate['dtFRetiro'] =  ''
        dataTraslate['strCodigoCargo'] =  employe[0].strCodigoCargo
        dataTraslate['strTipoCargo'] =  employe[0].strTipoCargo
        dataTraslate['strCodigoCentro'] =  data.seccion
        dataTraslate['strCodigoActividad'] =  employe[0].strCodigoActividad
        dataTraslate['Identificador_detalle'] =  employe[0].Identificador_detalle
        dataTraslate['Fecha_inicio_detalle'] =  employe[0].Fecha_inicio_detalle
        dataTraslate['Identificador_contratista'] =  employe[0].Identificador_contratista
        dataTraslate['Identificacion_contratista'] =  employe[0].Identificacion_contratista
        dataTraslate['Tipo_identificacion_contratista'] =  employe[0].Tipo_identificacion_contratista
        dataTraslate['Contratista'] =  employe[0].Contratista
        dataTraslate['Codigo_contratista'] =  employe[0].Codigo_contratista
        dataTraslate['Fecha_actualizacion'] =  data.fechatraslado
        dataTraslate['strCodigoCentro_EMPL'] =  employe[0].strCodigoCentro
        dataTraslate['dtFIngreso_EMPL'] =  employe[0].dtFIngreso
        dataTraslate['intIBM_TRAS'] =  data.sucursal
        dataTraslate['employeesId'] =  employe[0]._id

        dataTraslate['strMotivo'] =  motivoSeleted
        dataTraslate['dtFInicial'] =  data.fechatraslado
        motivoSeleted === 'Prestamo'?dataTraslate['dtFFinal'] =  data.fechafin : dataTraslate['dtFFinal'] = ''
            

        console.log(dataTraslate)
        traslateRequest(dataTraslate); 
    }
    const traslateRequest = async (dataTraslate) =>{             

        try {           
            const response = await requestHttp(
                {
                    endpoint: '/traslates',
                    body: dataTraslate,
                    token: getToken()
                }
            );   
            setEmployeSelected('');           
            setCodigoSelected('');   
            setEmploye('');                           
            showAlert(
                'Traslate', 
                "Creado Correctamente", 
                SW_ICON.SUCCESS                
            );
        } catch (error) {            
            showAlert('Error', "Traslate Incorrecto", SW_ICON.ERROR);
        }       
       
    }
    //Busqueda por Nombre
    const employeesFiltrados = busqueda === '' ? [] : employees.filter(employe => employe.strNombreTrabajador.toLowerCase().includes(busqueda.toLowerCase()))
    //Busqueda por centro
    const centrosFiltrados = motivoSeleted === 'Prestamo' ? centros.filter(centro => centro.strNombre.toLowerCase().includes(motivoSeleted.toLowerCase())) : [] 
    //console.log(centrosFiltrados)
         
    return(
    <Page >
        <AddNewsWrapper_Global>
            <AddNewsWrapper_Global_DOS>
                <LogoImageWrapper> 
                    <PageTitle>                   
                        PRESTAMOS Y TRASLADOS            
                    </PageTitle>         
                    <img src={"Logo.jpg"} alt="imgLogo"/>                               
                </LogoImageWrapper>
                
                <br />
                <form onSubmit={handleSubmit(onSubmitTraslate)}>
                    <FormControl>
                        <FormControlInputSearch>
                            <label>IDENTIFICACIÓN</label>
                            <input  type="number"                                    
                                     {...register("identificacionSearch")}
                                      value={employeSeleted} 
                                      onChange={e => setEmployeSelected(e.target.value)}                                                                     
                            />
                            
                        </FormControlInputSearch> 
                        <FormControlAction>
                            <ButtonIcon icon={IoSearchCircleSharp} onPress={handleEmployeDoc}/>                     
                        </FormControlAction>
                        
                        <FormControlInputSearch>
                            <label>CODIGO</label>
                            <input  type="number"                                    
                                     {...register("codigoSearch")}
                                      value={codigoSeleted} 
                                      onChange={e => setCodigoSelected(e.target.value)}                                                                     
                            />
                            
                        </FormControlInputSearch> 
                        <FormControlAction>
                            <ButtonIcon icon={IoSearchCircleSharp} onPress={handleEmployeCod}/>                     
                        </FormControlAction>                                         
                    </FormControl>                    
                    <FormControl>
                        <FormControlInputSearch>
                            <AddNewsCombo>
                                <Combobox
                                    as="div"                                                  
                                    onChange={(employe)=>(setEmploye([employe]))}                               
                                >
                                    <div className="relative">
                                        <Combobox.Input                            
                                            placeholder="Buscar por nombre..."
                                            onChange={ e => setBusqueda(e.target.value)}
                                        />
                                    </div>

                                    {employeesFiltrados.length > 0 && (
                                        <Combobox.Options static >
                                            { employeesFiltrados.map( employe =>
                                                <AddNewsBusqueda key={ employe.idTrabajador }>
                                                <Combobox.Option                                                    
                                                    value={ employe }                                                
                                                >
                                                    {employe.strNombreTrabajador +'  '+'( '+ employe.IntCodigo +' - '+  employe.strCodigoCentro +' )' }
                                                </Combobox.Option>
                                                </AddNewsBusqueda>
                                            )}
                                        </Combobox.Options>
                                    )}
                                </Combobox>
                            </AddNewsCombo>
                        </FormControlInputSearch>                        
                    </FormControl>
                    <AddNewsWrapper_Global_TRES>
                        <AddNewsWrapper_Global_DOS
                            basis='50%'
                        >
                            <FormControl>
                                <FormControlInput>
                                    <label>NOMBRE DEL TRABAJADOR</label>
                                    <input  type="text"                                    
                                            {...register("name")}
                                            value={employe[0]?employe[0].strNombreTrabajador:''}                                                                
                                    />
                                    
                                </FormControlInput>                                          
                            </FormControl>                              
                            <FormControl>
                                <FormControlInput>
                                    <label>IDENTIFICACIÓN</label>
                                    <input  type="number"                                    
                                            {...register("identificacion")}
                                            value={employe[0]?employe[0].strDocumento:''}                                                                                                      
                                    />                            
                                </FormControlInput> 
                                                                        
                            </FormControl>            
                            <FormControl>
                                <FormControlInput>
                                        <label>CODIGO</label>
                                        <input  type="number"                                    
                                                {...register("codigo")}    
                                                value={employe[0]?employe[0].IntCodigo:''}                                                                   
                                        />                            
                                </FormControlInput>                                                        
                            </FormControl>
                            <FormControl>
                                <FormControlInput>
                                    <label>FINCA A LA QUE PERTENECE</label>
                                    <input  type="text"                                    
                                            {...register("origen")} 
                                            value={employe[0]?employe[0].strCodigoCentro:''}                                                                              
                                    />
                                    
                                </FormControlInput>                                          
                            </FormControl>
                        </AddNewsWrapper_Global_DOS>
                        <AddNewsWrapper_Global_DOS
                            basis='50%'
                        >
                            <FormControl
                                color='#86b49d'                                
                            >
                                <FormControlInput>                                               
                                    <FormControlInputRadio  
                                        colortext='white'                           
                                    >                           
                                        <label>Prestamo</label>
                                        <input {...register("tipo")}  onChange={handleChangeMotivo} type="radio" value="Prestamo" />
                                        <label>Traslado</label>
                                        <input {...register("tipo")} onChange={handleChangeMotivo} type="radio" value="Traslado" />
                                    </FormControlInputRadio>                                        
                                </FormControlInput>                        
                            </FormControl>                  
                            <FormControl>
                                <FormControlInput>
                                    <label>{motivoSeleted === 'Prestamo'?'FINCA DE PRESTAMO':'FINCA DE TRASLADO'}</label>                    
                                    <select id="sucursal"
                                            {...register("sucursal", { required: true })}
                                            onChange={handleChange}>
                                        <option value={""}>--Seleccione--</option>
                                        {sucursal.map(item=>(                                    
                                                <option key={item.strCodigo} 
                                                        value={item.strCodigo}                                                                                               
                                                        id={item.strCodigo}
                                            >{item.strNombre}</option>
                                            )
                                            )
                                        }                                                                                                                                               
                                    </select>
                                </FormControlInput>                                          
                            </FormControl>  
                            <FormControl>
                                <FormControlInput>
                                    <label>EQUIPO DE TRABAJO</label>                    
                                    <select id="seccion"
                                            {...register("seccion", { required: true })}
                                            onChange={handleChangeC}>                            
                                        <option value={""}>--Seleccione--</option>
                                        {motivoSeleted === 'Traslado'? 
                                            centros.map(item=>(                                    
                                                <option key={item.strCodigo} 
                                                        value={item.strNombre}                                                                                               
                                                        id={item.strCodigo}
                                                >{item.strNombre}</option>
                                                )
                                            ):
                                            centrosFiltrados.map(item=>(                                    
                                                <option key={item.strCodigo} 
                                                        value={item.strNombre}                                                                                               
                                                        id={item.strCodigo}
                                                >{item.strNombre}</option>
                                                )
                                                )

                                        }                                                                                                                                                              
                                    </select>
                                </FormControlInput>                                          
                            </FormControl>                             
                            <FormControl>
                                <FormControlInput>
                                    <label>{motivoSeleted === 'Prestamo'?'FECHA INI':'FECHA DE TRASLADO'}</label>
                                    <input  type="date"
                                            {...register("fechatraslado", { required: true })} 
                                    />
                                    {errors.fechatraslado?.type === 'required' && <span>Este Campo es requerido</span>}
                                </FormControlInput>
                                {motivoSeleted === 'Prestamo'?
                                    <FormControlInput>                                    
                                        <label>FECHA FIN</label>
                                        <input  type="date"
                                                {...register("fechafin")} 
                                        />                                    
                                    </FormControlInput>:''}                                       
                            </FormControl>
                        </AddNewsWrapper_Global_DOS>                     
                    </AddNewsWrapper_Global_TRES>                                        
                    <br />
                    <Button disabled={!isValid} label={motivoSeleted === 'Prestamo'?'Registrar Prestamo':'Registrar Traslado'} type="submit" onPress={()=>{}} />
                    <br />                                                           
                </form>
                <br/>
                <FormControl>
                    <label>Ultimo prestamo ó traslado realizado al trabajador..</label>                                   
                </FormControl>
                {traslate.map((item, key) => 
                        <PropertyCard 
                            key={key}                   
                            {...item}                
                        />
                        )
                }
            </AddNewsWrapper_Global_DOS>
        </AddNewsWrapper_Global>        
    </Page>
);};