import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth"
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useRdt } from "../../hooks/useRdt";
import { getToken, removeToken } from "../../utils/TokenLS";
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { IoAnalyticsOutline } from "react-icons/io5";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";
import { FormControl, FormControlInput, SubTitle } from "../../globalStyles";
import { Page } from "../../components/Page";
import {
  PropertyTypesContainerColum,
  PropertyTypesContainerHead,
  PropertyTypesContainerRow,
  RdtsDetailTitle,
  RdtsWrapper,
  RdtsDetailInfo
} from "./styles";
import {
  PropertyButtonWrapper, PropertyCardWrapper,
  PropertyCardWrapperTr,
  PropertyDescriptionWrapper,
  PropertyImageWrapper,
  PropertyInfoWrapper,
  PropertyValueWrapper
} from "../Rdts/PropertyCardRdts/styles";
import { PropertyProfile } from "../Home/components/PropertyProfile";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { ButtonIconRemove } from "../../components/ButtonIconRemove";
import {
  IoTrashOutline,
  IoRemoveCircleOutline,
  IoRadioButtonOffOutline,
  IoArrowBackCircleOutline,
  IoArrowBack,
  IoDuplicate,
  IoAddCircleOutline,
  IoSave,
  IoSend
} from "react-icons/io5";
import swal from 'sweetalert';
import Alert from "../../components/Alert/Alert"
import { Button } from "../../components/Button";
import { ButtonIcon } from "../../components/ButtonIcon";
import { DetailsAnalysesRdts } from "./components/DetailsAnalysesRdts";
import { DetailsAnalysesRdtsEmb } from "./components/DetailAnalysesRdtsEmb";
import { DetailsAnalysesRdtsGua } from "./components/DetailAnalysesRdtsGua";
import { Rdts } from "../Rdts";


const ALL_SUCURSALES = 0;
const fecha = Date.now();
const suma = 0;

export const Rdtsanalyses = () => {

  const { auth, closeSeccionAuth } = useAuth();
  const { rdtprov, setRdtprov } = useRdt();
  const [rdts, setRdts] = useState([]);
  const [sucursal, setSucursal] = useState([]);
  const [sucursalSeleted, setSucursalSelected] = useState(ALL_SUCURSALES);
  const [sucursaludps, setSucursaludps] = useState([]);
  const [analyses, setAnalyses] = useState({});
  const [analysesrdts, setAnalysesrdts] = useState([]);

  const [codtrabajador, setCodtrabajador] = useState('');
  const [concepto, setConcepto] = useState([]);
  const [codlabor, setCodlabor] = useState('');
  const [codudp, setCodudp] = useState('');
  const [fechaini, setFechaIni] = useState(fecha);
  const [fechafin, setFechafin] = useState(fecha);
  const [date, setDate] = useState('');

  const [sumtotal, setSumtotal] = useState(suma);
  const [sumcant, setSumcant] = useState(suma);
  const [sumcantdos, setSumcantdos] = useState(suma);
  const [sumcanttres, setSumcanttres] = useState(suma);
  const [sumcantcuatro, setSumcantcuatro] = useState(suma);
  const [sumhoras, setSumhoras] = useState(suma);

  const [alert, setAlert] = useState({})

  //Para el analisis detallado por udps cuando realizamos lab 118 Embolse y 119 Colocar Guantelete
  const [modal, setModal] = useState(false);
  const [animarModal, setAnimarModal] = useState(false);
  const [uniquecintas, setUniquecintas] = useState();
  const [sucursalName, setSucursalName] = useState();
  //Para mostrar los modales pertenecientes a cada labor consultada 118 y 119
  const [laborShow, setLaborshow] = useState(0);


  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isValid
    }
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    const getData = async () => {
      try {
        const token = getToken();
        if (!token) return
        await getSucursalesAll();
      } catch (error) {
        console.log(error)
      }
    }
    getData();
  }, [auth])

  //Si estoy editando
  useEffect(() => {
    const getData = async () => {
      try {
        const token = getToken();
        if (!token) return
        await rdtprov.ibm ? setSucursalSelected(rdtprov.ibm) : console.log('');
        await rdtprov.dateRdt ? setFechaIni((rdtprov.dateRdt).split('T')[0]) : console.log('');
      } catch (error) {
        console.log(error)
      }
    }
    getData();
  }, [rdtprov])

  useEffect(() => {
    const getDataRdts = async () => {
      try {
        const token = getToken();
        if (!token) return
        getUdps();
        getConcepto();
        getRdts();
        setAnalysesrdts([]);
      } catch (error) {
        console.log(error)
      }
    }
    getDataRdts();
  }, [sucursalSeleted])

  useEffect(() => {
    const getDataRdts = async () => {
      try {
        const token = getToken();
        if (!token) return
        getRdts();
        setAnalysesrdts([]);
      } catch (error) {
        console.log(error)
      }
    }
    getDataRdts();
  }, [fechaini])

  useEffect(() => {
    const getDataRdts = async () => {
      try {
        const token = getToken();
        if (!token) return
        getRdts();
        setAnalysesrdts([]);
      } catch (error) {
        console.log(error)
      }
    }
    getDataRdts();
  }, [fechafin])

  useEffect(() => {
    const getDataRdts = async () => {
      try {
        const token = getToken();
        if (!token) return
        getRdts();
      } catch (error) {
        console.log(error)
      }
    }
    getDataRdts();
  }, [codtrabajador])

  useEffect(() => {
    const getDataRdts = async () => {
      try {
        const token = getToken();
        if (!token) return
        await sucursalSeleted ? getConcepto() : console.log('');
        await getRdts();
        setAnalysesrdts([]);
      } catch (error) {
        console.log(error)
      }
    }
    getDataRdts();
  }, [codlabor])

  useEffect(() => {
    const getDataRdts = async () => {
      try {
        const token = getToken();
        if (!token) return
        getRdtsUdp();

      } catch (error) {
        console.log(error)
      }
    }
    getDataRdts();
  }, [codudp])

  useEffect(() => {
    const getDataRdts = async () => {
      try {
        const token = getToken();
        if (!token) return
        setAnalysesrdts([])
      } catch (error) {
        console.log(error)
      }
    }
    getDataRdts();
  }, [rdts])

  useEffect(() => {
    const getDataRdts = async () => {
      try {
        const token = getToken();
        if (!token) return
        await analysesrdts ? onSubmitCantidad() : console.log('');
      } catch (error) {
        console.log(error)
      }
    }
    getDataRdts();
  }, [analysesrdts])

  //Sucursales
  const handleChange = event => {
    setSucursalSelected(event.target.value)
  }
  const handleChangeDate = event => {
    setFechaIni(event.target.value)
  }
  const handleChangeDatefin = event => {
    setFechafin(event.target.value)
  }
  const handleChangeCodtrabajador = event => {
    setCodtrabajador(event.target.value)
  }
  const handleChangeCodlabor = event => {
    setCodlabor(event.target.value)
  }
  const handleChangeCodudp = event => {
    setCodudp(event.target.value)
  }
  //Sucursals
  const getSucursalesAll = async () => {
    try {
      const response = await requestHttp(
        {
          method: HTTP_VERBS.GET,
          endpoint: '/sucursal',
          params: makeSucursalesFilters()
        }
      );
      const { data } = response;
      setSucursal(data);
    } catch (error) {
      console.log(error)
    }
  }
  const makeSucursalesFilters = () => {
    const filters = {};
    return filters;
  }
  //Concepts
  const getConcepto = async () => {
    if (sucursalSeleted) {
      try {
        const response = await requestHttp(
          {
            method: HTTP_VERBS.GET,
            endpoint: '/conceptossucursal/allconceptos',
            params: makeConceptoFilters()
          }
        );
        const { data } = response;
        //console.log({datos: data})   
        data ? setConcepto(data) : setConcepto({});

      } catch (error) {
        console.log(error)
      }

    } else {
      //setConcepto({});
    }
  }
  const makeConceptoFilters = () => {
    const filters = {};
    if (sucursalSeleted !== ALL_SUCURSALES) {
      filters['Ibm'] = sucursalSeleted;
      filters['IntCodigo'] = codlabor;
    }
    //console.log({filters: filters});         
    return filters;
  }
  //Close session if token expired
  const hendleCloseSeccion = async () => {
    closeSeccionAuth()
    removeToken()
    showAlert(
      'Inicie Seccion Nuevamente',
      "Su token puede estar vencido",
      SW_ICON.INFO,
      () => { navigate('/Login') }
    );
  }
  //getRdts
  const getRdts = async () => {
    //console.log({sucursal: sucursalSeleted})
    if (!fechaini) {
      setRdts([])
      return
      //console.log('Fecha vacia')
    }
    if (sucursalSeleted === '' || sucursalSeleted === 0 || sucursalSeleted === '90021' || sucursalSeleted === '90020') {
      setRdts([])
      return
      //console.log({nohacerConsulta: sucursalSeleted})        
    } else {
      try {
        const response = await requestHttp(
          {
            method: HTTP_VERBS.GET,
            endpoint: '/Rdts',
            params: makePropertiesFilters()
          }
        );
        const { data } = response;
        data ? setRdts(data) : console.log('');
        data ? setSucursalName(data[0].strFincaLaboro) : console.log('');
      } catch (error) {
        if (error.response.status === 401) {
          hendleCloseSeccion();
        } else {
          console.log(error)
        }
      }
      //console.log({hacerConsulta: sucursalSeleted})        
    }
  }
  //getUdps
  const getUdps = async () => {
    //console.log({sucursal: sucursalSeleted})   
    if (!fechaini) {
      setSucursaludps([])
      return
      //console.log('Fecha vacia')
    }
    if (sucursalSeleted === '' || sucursalSeleted === 0 || sucursalSeleted === '90021' || sucursalSeleted === '90020') {
      setSucursaludps([])
      return
      //console.log({nohacerConsulta: sucursalSeleted})        
    } else {
      try {
        const response = await requestHttp(
          {
            method: HTTP_VERBS.GET,
            endpoint: '/udps',
            params: makePropertiesFilters()
          }
        );
        const { data } = response;
        data ? setSucursaludps(data) : console.log('');
      } catch (error) {
        if (error.response.status === 401) {
          hendleCloseSeccion();
        } else {
          console.log(error)
        }
      }
      //console.log({ConsultaUdps: sucursaludps})        
    }
  }
  //getRdts x udp
  const getRdtsUdp = async () => {
    //console.log({sucursal: sucursalSeleted})
    if (!fechaini) {
      setRdts([])
      return
      //console.log('Fecha vacia')
    }
    if (sucursalSeleted === '' || sucursalSeleted === 0 || sucursalSeleted === '90021' || sucursalSeleted === '90020') {
      setRdts([])
      return
      //console.log({nohacerConsulta: sucursalSeleted})        
    } else {
      try {
        const response = await requestHttp(
          {
            method: HTTP_VERBS.GET,
            endpoint: '/Rdts/analyses',
            params: makePropertiesFilters()
          }
        );
        const { data } = response;
        data ? setRdts(data) : console.log('');
      } catch (error) {
        if (error.response.status === 401) {
          hendleCloseSeccion();
        } else {
          console.log(error)
        }
      }
      //console.log({hacerConsulta: sucursalSeleted})        
    }
  }
  //Sumar cantidad
  const onSubmitCantidad = async (data) => {
    if (Object.keys(analysesrdts).length > 0) {
      //console.log('en submitCant')
      let a = 0;
      let b = 0;
      let c = 0;
      let d = 0;
      let e = 0;
      for (let i = 0; i < analysesrdts.length; i++) {
        a = a + analysesrdts[i].A_Neta;
        b = b + analysesrdts[i].A_Bruta;
        c = c + analysesrdts[i].realizado;
        d = d + analysesrdts[i].Falta;
        e = e + analysesrdts[i].Falta;
      }
      setSumtotal(a)
      setSumcant(b)
      setSumcantdos(c)
      setSumcanttres(d)
      setSumcantcuatro(e)
      setSumhoras(0)
    } else {
      setSumtotal(0);
      setSumcant(0);
      setSumcantdos(0)
      setSumcanttres(0)
      setSumcantcuatro(0)
      setSumhoras(0);
    }

  }
  const makePropertiesFilters = () => {
    const filters = {};
    filters['Fecha_INI'] = fechaini;
    filters['Fecha_FIN'] = fechafin;
    filters['IbmLaboro'] = sucursalSeleted;
    filters['IntCodigo_EMPL'] = codtrabajador;
    filters['IntCodigo_CONC'] = codlabor;
    filters['IntCodigo_UDP'] = codudp;
    filters['Ibm'] = sucursalSeleted;
    filters['intClase'] = 2;
    // filters['employeesId'] = auth.data._id;     
    //console.log({fechafin: fechafin});

    return filters;
  }
  //Delete RDT
  const showAlertYN = (id) => {
    //Validar dia de la semana
    const fechaComoCadena = fechaini ? fechaini.split('T')[0] + " 00:01:59" : console.log(''); // día semana  
    const fecha = new Date(fechaComoCadena);
    //hoy
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    const rango = new Date(hoy - fecha);
    const tiempo = new Date(189999999);

    //console.log({rango: rango})
    //console.log({tiempo: tiempo})

    //Vallido que si este dentro de un rango permitido para duplicar
    if (rango > 189999999) {
      setAlert({
        msg: 'Usted ya no puede eliminar este registro, porque esta fuera del tiempo permitido',
        error: true
      })
      //console.log({mensaje: alert.msg}); 
      setTimeout(() => {
        setAlert({})
      }, 7000)
      return
    }
    //console.log({id: id})
    swal({
      title: "Esta usted seguro?",
      text: "Una vez eliminado, no podras recuperar este registro!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          rdtDelete(id)
        } else {
          //swal("Su registro esta a salvo!");
          return
        }
        //console.log(willDelete)
      });
  }
  const rdtDelete = async (id) => {
    try {
      const response = await requestHttp(
        {
          method: HTTP_VERBS.DELETE,
          endpoint: `/rdts/${id}`,
          token: getToken()
        }
      );
      getRdts()
      showAlert(
        'RDT',
        "Eliminado Correctamente",
        SW_ICON.SUCCESS
      );

    } catch (error) {
      if (error.response.status === 401) {
        //hendleCloseSeccion();
      } else {
        showAlert('Error', "Eliminación Incorrecta", SW_ICON.ERROR);
      }
    }
  }

  const onSubmitUdps = async (data) => {
    //Iniciailizo en cero, para que se vuelva a ejecutar cuando se presione el boton d analisis para labor 118 y 119  en handleAnalysesRdts       
    setLaborshow(0);
    if (rango < 0) {
      setAlert({
        msg: 'Utilice un rango de fecha valido, "Fecha Inicial" no puede ser mayor a "Fecha Final"',
        error: true
      })
      //console.log({mensaje: alert.msg}); 
      setTimeout(() => {
        setAlert({})
      }, 7000)
      return
    }
    if (concepto.length > 1) {
      if (rango > 1123200000) {
        setAlert({
          msg: 'Usted ha excedido el rango de consulta mayor a "14" dias',
          error: true
        })
        //console.log({mensaje: alert.msg}); 
        setTimeout(() => {
          setAlert({})
        }, 7000)
        return
      }
    }

    let products = [];
    if (concepto.length <= 1) {
      //Recorro solo el concepto filtrado          
      for (let i = 0; i < sucursaludps.length; i++) {

        let acumCant = 0;
        let acumCantPm = 0;
        let acumCantPs = 0;

        for (let b = 0; b < rdts.length; b++) {
          if (sucursaludps[i].IntCodigo === rdts[b].IntCodigo_UDP) {
            acumCant = acumCant + rdts[b].intCantidad_UDP
            /*//Para las cintas
            for (let c = 0; c < rdts[b].strUdp.length; c++){
              if(sucursaludps[i].IntCodigo === rdts[b].strUdp[c].IntCodigo_UDP){
                acumCantPm = acumCantPm + rdts[b].strUdp[c].intCantidadPm_UDP
                acumCantPs = acumCantPs + rdts[b].strUdp[c].intCantidadPs_UDP
                //console.log({cintaPm:rdts[b].strUdp[c].strCintaPm_UDP})
                //console.log({cintaPs:rdts[b].strUdp[c].strCintaPs_UDP})
              }
            }
            //console.log(rdts[b])  
            */
          }
          if (sucursaludps[i].IntCodigo === rdts[b].IntCodigo_UDPdos) {
            acumCant = acumCant + rdts[b].intCantidad_UDPdos
            /*//Para las cintas
            for (let c = 0; c < rdts[b].strUdp.length; c++){
              if(sucursaludps[i].IntCodigo === rdts[b].strUdp[c].IntCodigo_UDP){
                acumCantPm = acumCantPm + rdts[b].strUdp[c].intCantidadPm_UDP
                acumCantPs = acumCantPs + rdts[b].strUdp[c].intCantidadPs_UDP
                //console.log({cintaPm:rdts[b].strUdp[c].strCintaPm_UDP})
                //console.log({cintaPs:rdts[b].strUdp[c].strCintaPs_UDP})
              }
            }
            //console.log(rdts[b])  
            */
          }
          if (sucursaludps[i].IntCodigo === rdts[b].IntCodigo_UDPtres) {
            acumCant = acumCant + rdts[b].intCantidad_UDPtres
            /*//Para las cintas
            for (let c = 0; c < rdts[b].strUdp.length; c++){
              if(sucursaludps[i].IntCodigo === rdts[b].strUdp[c].IntCodigo_UDP){
                acumCantPm = acumCantPm + rdts[b].strUdp[c].intCantidadPm_UDP
                acumCantPs = acumCantPs + rdts[b].strUdp[c].intCantidadPs_UDP
                console.log({cintaPm:rdts[b].strUdp[c].strCintaPm_UDP})
                console.log({cintaPs:rdts[b].strUdp[c].strCintaPs_UDP})
              }
            }
            //console.log(rdts[b])  
            */
          }
          if (sucursaludps[i].IntCodigo === rdts[b].IntCodigo_UDPcuatro) {
            acumCant = acumCant + rdts[b].intCantidad_UDPcuatro
            /*//Para las cintas
            for (let c = 0; c < rdts[b].strUdp.length; c++){
              if(sucursaludps[i].IntCodigo === rdts[b].strUdp[c].IntCodigo_UDP){
                acumCantPm = acumCantPm + rdts[b].strUdp[c].intCantidadPm_UDP
                acumCantPs = acumCantPs + rdts[b].strUdp[c].intCantidadPs_UDP
                console.log({cintaPm:rdts[b].strUdp[c].strCintaPm_UDP})
                console.log({cintaPs:rdts[b].strUdp[c].strCintaPs_UDP})
              }
            }
            //console.log(rdts[b]) */
          }

        }

        const h = {
          Codigo: concepto[0].IntCodigo,
          Labor: concepto[0].strConcepto,
          Vueltas: concepto[0].intVueltas,
          Lote: sucursaludps[i].IntCodigo,
          A_Neta: sucursaludps[i].intANeta,
          A_Bruta: sucursaludps[i].intABruta,
          Falta: acumCant - (sucursaludps[i].intANeta * concepto[0].intVueltas),
          realizado: acumCant,
          //Prematuro: acumCantPm,
          //Presente: acumCantPs

        }
        products.push(h);

      }
      //console.log({products: products})
      setAnalysesrdts(products)
      //console.log({analysesFin: analysesrdts})

      //Busco cintas
      let cintas = [];
      for (let h = 0; h < rdts.length; h++) {
        for (let j = 0; j < rdts[h].strUdp.length; j++) {
          const ci = {
            cinta: rdts[h].strUdp[j].strCinta_UDP,
          }
          cintas.push(ci);
          //console.log(rdts[h].strUdp[j].strCintaPm_UDP)
          //console.log(rdts[h].strUdp[j].strCintaPs_UDP)
        }
      }
      //console.log({cintas: cintas})

      // Primero, vamos a eliminar los objetos con valores vacíos en ambas propiedades
      const filteredObjects = cintas.filter(objeto => objeto.cinta !== '');

      // Luego, vamos a eliminar los objetos duplicados basados en su propiedad cinta
      const uniqueCintas = filteredObjects.filter((objeto, index, self) =>
        index === self.findIndex(obj => obj.cinta === objeto.cinta)
      );

      //console.log({sinVacios: filteredObjects});
      //console.log({unicos: uniqueCintas});
      setUniquecintas(uniqueCintas);

    } else {
      for (let z = 0; z < concepto.length; z++) {

        if (concepto[z].intEXUDP > 0) {
          //console.log(concepto[z].IntCodigo)
          for (let i = 0; i < sucursaludps.length; i++) {

            let acumCant = 0;

            for (let b = 0; b < rdts.length; b++) {
              if ((sucursaludps[i].IntCodigo === rdts[b].IntCodigo_UDP) && (concepto[z].IntCodigo === rdts[b].IntCodigo_CONC)) {
                acumCant = acumCant + rdts[b].intCantidad_UDP
                //console.log(rdts[b])  

              }
              if ((sucursaludps[i].IntCodigo === rdts[b].IntCodigo_UDPdos) && (concepto[z].IntCodigo === rdts[b].IntCodigo_CONC)) {
                acumCant = acumCant + rdts[b].intCantidad_UDPdos
                //console.log(rdts[b])  

              }
              if ((sucursaludps[i].IntCodigo === rdts[b].IntCodigo_UDPtres) && (concepto[z].IntCodigo === rdts[b].IntCodigo_CONC)) {
                acumCant = acumCant + rdts[b].intCantidad_UDPtres
                //console.log(rdts[b])  

              }
              if ((sucursaludps[i].IntCodigo === rdts[b].IntCodigo_UDPcuatro) && (concepto[z].IntCodigo === rdts[b].IntCodigo_CONC)) {
                acumCant = acumCant + rdts[b].intCantidad_UDPcuatro
                //console.log(rdts[b])  

              }

            }

            if (acumCant > 0) {
              const h = {
                Codigo: concepto[z].IntCodigo,
                Labor: concepto[z].strConcepto,
                Vueltas: concepto[z].intVueltas,
                Lote: sucursaludps[i].IntCodigo,
                A_Neta: sucursaludps[i].intANeta,
                A_Bruta: sucursaludps[i].intABruta,
                Falta: acumCant - (sucursaludps[i].intANeta * concepto[z].intVueltas),
                realizado: acumCant
              }
              products.push(h);
            }

          }
          //console.log({products: products})
          //setAnalysesrdts(products)
          //console.log({analysesFin: analysesrdts})  

        } else {

          let acumCant = 0;
          for (let b = 0; b < rdts.length; b++) {
            if ((concepto[z].IntCodigo === rdts[b].IntCodigo_CONC)) {
              acumCant = acumCant + rdts[b].intTotal_RDT
              //console.log(rdts[b])  

            }
          }
          if (acumCant > 0) {
            const h = {
              Codigo: concepto[z].IntCodigo,
              Labor: concepto[z].strConcepto,
              Vueltas: '',
              Lote: '',
              A_Neta: 0,
              A_Bruta: 0,
              Falta: 0,
              realizado: acumCant
            }
            products.push(h);
            //console.log({acum: h})
          }
        }
      }
      setAnalysesrdts(products)
    }

  }

  const FechaI = new Date(fechaini)
  const FechaF = new Date(fechafin)

  const rango = (FechaF - FechaI);
  //const newRango = new Date(rango)
  //console.log({rangoNo: rango})
  //console.log({rangoFecha: newRango})
  //console.log({sucursal: sucursalSeleted})

  //Ventana modal para detalle de analisis de RDT
  const handleAnalysesRdts = () => {
    //Para mostrar los modales pertenecientes a cada labor consultada 118 y         
    setLaborshow(parseInt(codlabor));
    setModal(true)

    setTimeout(() => {
      setAnimarModal(true)
    }, 300);
  }

  const { msg } = alert;

  //console.log({laborShow: laborShow})

  return (
    <Page>
      <RdtsDetailTitle>
        ANALISIS DE RDTS
      </RdtsDetailTitle>
      {msg && <Alert alert={alert} />}
      <form>
        <PropertyTypesContainerRow>
          <PropertyTypesContainerColum>
            <LogoImageWrapper>
              <img src={"Logodos.jpg"} alt="imgLogo" />
            </LogoImageWrapper>
          </PropertyTypesContainerColum>
          <PropertyTypesContainerColum>
            <FormControl>
              <FormControlInput>
                <label>FINCA REPORTADA</label>
                <select id="sucursal"
                  {...register("sucursal", { required: true })}
                  onChange={handleChange}
                  value={sucursalSeleted}
                  disabled={auth.data.role !== 1 && rdtprov.ibm !== 90021 ? true : false}>
                  <option value={""}>--Seleccione--</option>
                  {sucursal.map(item => (
                    <option key={item.strCodigo}
                      value={item.strCodigo}
                      id={item.strCodigo}
                    >{item.strNombre}</option>
                  )
                  )
                  }

                </select>
              </FormControlInput>
            </FormControl>
            <FormControl>
              <FormControlInput>
                <label>FECHA INICIAL</label>
                <input type="date"
                  {...register("fechaini")}
                  onChange={handleChangeDate}
                  value={fechaini}
                />
                {errors.fechaini?.type === 'required' && <span>Este Campo es requerido</span>}
              </FormControlInput>
            </FormControl>
            <FormControl>
              <FormControlInput>
                <label>FECHA FINAL</label>
                <input type="date"
                  {...register("fechafin")}
                  onChange={handleChangeDatefin}
                  value={fechafin}
                />
                {errors.fechafin?.type === 'required' && <span>Este Campo es requerido</span>}
              </FormControlInput>
            </FormControl>
            <Button label="CONSULTAR" onPress={(onSubmitUdps)} />
          </PropertyTypesContainerColum>
          <PropertyTypesContainerColum>
            <FormControl>
              <FormControlInput>
                <label>COD.TRABAJDOR:</label>
                <input type='text'
                  {...register("codtrabajador")}
                  onChange={handleChangeCodtrabajador}
                />
              </FormControlInput>
            </FormControl>
            <FormControl>
              <FormControlInput>
                <label>COD.LABOR:</label>
                <input type='text'
                  {...register("codlabor")}
                  onChange={handleChangeCodlabor}
                />
              </FormControlInput>
            </FormControl>
            <FormControl>
              <FormControlInput>
                <label>UDP:</label>
                <input type='text'
                  {...register("codudp")}
                  onChange={handleChangeCodudp}
                />
              </FormControlInput>
            </FormControl>
          </PropertyTypesContainerColum>
          <PropertyTypesContainerColum>
            <p>
              USU:<span>{auth.data ? auth.data.email : 'Usuario no registrado'}</span><br />
              IBM:<span>{sucursalSeleted}</span><br />
              FEC:<span>{fechaini}</span><br /><br />

            </p>
            {
              concepto.length == 1 && analysesrdts.length > 0 && codlabor == 118 ? <ButtonIcon icon={IoAnalyticsOutline} onPress={handleAnalysesRdts} />
                : concepto.length == 1 && analysesrdts.length > 0 && codlabor == 119 ? <ButtonIcon icon={IoAnalyticsOutline} onPress={handleAnalysesRdts} />
                  : concepto.length == 1 && analysesrdts.length > 0 && codlabor == 451 ? <ButtonIcon icon={IoAnalyticsOutline} onPress={handleAnalysesRdts} />
                    : ''
            }
            {
              modal && laborShow === 119 || modal && laborShow === 451 ?
                <DetailsAnalysesRdtsGua
                  setModal={setModal}
                  animarModal={animarModal}
                  ibm={sucursalSeleted}
                  fechaini={fechaini}
                  fechafin={fechafin}
                  codLabor={codlabor}
                  cintas={uniquecintas}
                  global={analysesrdts}
                  sucursalName={sucursalName}
                />
                : ''

            }
            {
              modal && laborShow === 118 ?
                <DetailsAnalysesRdtsEmb
                  setModal={setModal}
                  animarModal={animarModal}
                  ibm={sucursalSeleted}
                  fechaini={fechaini}
                  fechafin={fechafin}
                  codLabor={codlabor}
                  cintas={uniquecintas}
                  global={analysesrdts}
                  sucursalName={sucursalName}
                />
                : ''

            }
          </PropertyTypesContainerColum>
          <PropertyTypesContainerColum>
            <LogoImageWrapper>

            </LogoImageWrapper>
          </PropertyTypesContainerColum>

        </PropertyTypesContainerRow>
      </form>
      <br />
      <table>
        <thead>
          <tr>
            <th>Codigo</th>
            <th>Labor</th>
            <th>Vueltas</th>
            <th>-</th>
            <th>Lote</th>
            <th>-</th>
            <th>A.Neta</th>
            <th>-</th>
            <th>A.Bruta</th>
            <th>-</th>
            <th>Realizado</th>
            <th>-</th>
            <th>Pendiente</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {analysesrdts.map((item, key) => (
            <tr>
              <th>

                <PropertyCardWrapperTr>
                  {item.Codigo}

                </PropertyCardWrapperTr>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {item.Labor}
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  <p>{item.Vueltas}</p>
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>

                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {item.Lote}
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>

                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  <p>{(item.A_Neta).toFixed(2)}</p>
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>

                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {(item.A_Bruta).toFixed(2)}
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>

                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {(item.realizado).toFixed(2)}
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>

                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {(item.Falta).toFixed(2)}
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  <p></p>
                </PropertyDescriptionWrapper>
              </th>

              <th>
                <PropertyDescriptionWrapper>
                  <p></p>
                </PropertyDescriptionWrapper>
              </th>

              <th>
                <PropertyDescriptionWrapper>
                  <p></p>
                </PropertyDescriptionWrapper>
              </th>

            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th>T.REG: {analysesrdts.length}</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>{(sumtotal).toFixed(2)}</th>
            <th></th>
            <th>{(sumcant).toFixed(2)}</th>
            <th></th>
            <th>{(sumcantdos).toFixed(2)}</th>
            <th></th>
            <th>{(sumcanttres).toFixed(2)}</th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </Page>
  )
}


